/* @codegen */

import { InputArguments, request, TypedResponse } from "./client";

/**
 * AdminDeliveryEntry
 */
export type AdminDeliveryEntry = {
  delivery_request: {
    delivery_client_charge: number; // 請求金額
    delivery_client_name: string; // クライアントの名前
    destination_name: string; // 配達先の名前
    distance_kilo_meter: number; // 配達距離
    id: number; // リクエストID
    pick_up_point_name: string; // 集荷先の名前
    published_at: string; // 公開時刻
    status: AdminDeliveryRequestStatus;
  };
  id: number; // 応募ID
};

/**
 * AdminDeliveryEntryPage
 */
export type AdminDeliveryEntryPage = {
  delivery_entries: AdminDeliveryEntry[];
  pagination: Pagination;
};

/**
 * AdminDeliveryRequestDetailV2
 */
export type AdminDeliveryRequestDetailV2 = {
  charge_breakdown?: ChargeBreakdown;
  correspondence: boolean; // 信書かどうか
  delivery_client_charge_amount?: number | null; // 料金
  delivery_client_charge_status?: "in_progress" | "succeeded" | "failed"; // 決済状況
  delivery_entry?: {
    driver: {
      family_name: string; // ドライバーの姓
      phone_number?: string; // 電話番号
    };
    id: number;
  };
  delivery_request_tags: {
    id: number;
    name: string;
  }[];
  destinations: {
    address: string; // 配達先の住所全体
    event_time_prediction?: EventTimePrediction;
    message_from_delivery_client: string; // クライアントからの伝達事項
    name: string; // 配達先の名前
    phone_number: string; // 配達先の電話番号
    pick_up_id?: string; // 注文番号
    task_status: RoutePointTaskStatus;
  }[];
  distance_kilo_meter: number; // 配達距離
  id: number;
  marketplace_matched_delivery_network?: {
    delivery_price_amount: number;
    network_type: NetworkType;
  };
  matching_target: "marketplace" | "contracted_driver";
  message_from_delivery_client: string; // クライアントからの伝達事項
  organization_unit?: {
    id: number;
    name: string;
    recruitment_targets: OrganizationUnitRecruitmentTarget[]; // リクエストの配信対象
  }; // リクエストの紐づいているユニット
  pick_up_point: {
    address: string; // 集荷先の住所全体
    event_time_prediction?: EventTimePrediction;
    message_from_delivery_client: string; // クライアントからの伝達事項
    name: string; // 集荷先の名前
    phone_number: string; // 集荷先の電話番号
  };
  proxy_arrangement_finished_event?: ProxyArrangementFinishedEvent;
  publishable_after: string; // リクエストをpublish可能になる時刻
  published_at: string; // リクエストがpublishされた時刻
  recruitment_finished_at?: string; // 募集終了時刻
  required_driver_skills: {
    id: number;
    name: string;
  }[];
  status: AdminDeliveryRequestStatus;
};

/**
 * AdminDeliveryRequestStatus
 */
export type AdminDeliveryRequestStatus =
  | "buffered"
  | "reserved"
  | "canceled"
  | "in_recruitment"
  | "go_to_pick_up"
  | "in_delivery"
  | "finished"
  | "closed"
  | "proxy_arrangement"
  | "proxy_arrangement_finished";

/**
 * AdminDeliveryRequestV2
 */
export type AdminDeliveryRequestV2 = {
  delivery_client_charge_amount?: number; // 料金
  destination_names: string[]; // 配達先
  distance_kilo_meter: number; // 配達距離
  id: number;
  marketplace_matched_delivery_network?: {
    delivery_price_amount: number;
    network_type: NetworkType;
  };
  organization_unit?: {
    id: number;
    name: string;
  };
  pick_up_point_name: string; // 集荷先
  pricing_model?: PricingModel;
  publishable_after: string; // リクエストをpublish可能になる時刻
  published_at: string; // リクエストがpublishされた時刻
  recruitment_finished_at?: string; // 募集終了時刻
  status: AdminDeliveryRequestStatus;
};

/**
 * AdminDriver
 */
export type AdminDriver = {
  email: string;
  full_name: string;
  id: number;
  is_inviting: boolean; // 招待中かどうか
  organization_units: AdminOrganizationUnitSimple[];
  phone_number: string;
  status: DriverStatus;
};

/**
 * AdminDriverPage
 */
export type AdminDriverPage = {
  drivers: AdminDriver[];
  pagination: Pagination;
};

/**
 * AdminDriverProfile
 */
export type AdminDriverProfile = {
  email: string;
  family_name: string; // 姓
  family_name_furigana: string; // 姓の平仮名のふりがな
  given_name: string; // 名
  given_name_furigana: string; // 名の平仮名のふりがな
  id: number;
  is_inviting: boolean; // 招待中かどうか
  organization_units: AdminOrganizationUnitSimple[];
  phone_number: string; // 電話番号
  status: DriverStatus;
};

/**
 * 集合場所
 */
export type AdminDriverStandbyArea = {
  coordinates?: Coordinates;
  id: number;
  name: string;
  note?: string; // 集合場所に関する詳細メモ
  organization_unit: AdminOrganizationUnitSimple;
};

/**
 * AdminDriverStandbyAreaCreatePayload
 */
export type AdminDriverStandbyAreaCreatePayload = {
  driver_standby_area: {
    coordinates?: Coordinates;
    name: string;
    note?: string;
    organization_unit_id: number;
  };
};

/**
 * AdminDriverStandbyAreaPage
 */
export type AdminDriverStandbyAreaPage = {
  driver_standby_areas: AdminDriverStandbyArea[];
  pagination: Pagination;
};

/**
 * AdminDriverStandbyAreaUpdatePayload
 */
export type AdminDriverStandbyAreaUpdatePayload = {
  driver_standby_area: {
    coordinates?: Coordinates;
    name?: string;
    note?: string;
  };
};

/**
 * 自社配達員シフト
 */
export type AdminInhouseDriverShift = {
  check_in_status: InhouseDriverShiftCheckinStatus;
  driver?: {
    id: number;
    name: string;
  };
  driver_standby_area: AdminDriverStandbyArea;
  id: number; // 自社配達員シフトID
  organization_unit: AdminOrganizationUnitSimple;
  shift_working_time: ShiftWorkingTime;
};

/**
 * AdminInhouseDriverShiftBulkCreatePayload
 */
export type AdminInhouseDriverShiftBulkCreatePayload = {
  inhouse_driver_shifts: {
    driver_id?: number;
    driver_standby_area_id: number;
    organization_unit_id: number;
    shift_working_time: ShiftWorkingTime;
  }[];
};

/**
 * AdminInhouseDriverShiftCreatePayload
 */
export type AdminInhouseDriverShiftCreatePayload = {
  inhouse_driver_shift: {
    driver_id?: number;
    driver_standby_area_id: number;
    organization_unit_id: number;
    shift_working_time: ShiftWorkingTime;
  };
};

/**
 * AdminInhouseDriverShiftPage
 */
export type AdminInhouseDriverShiftPage = {
  inhouse_driver_shifts: AdminInhouseDriverShift[];
  pagination: Pagination;
};

/**
 * AdminInhouseDriverShiftUpdatePayload
 */
export type AdminInhouseDriverShiftUpdatePayload = {
  inhouse_driver_shift: {
    driver_id?: number;
    driver_standby_area_id?: number;
    shift_working_time?: ShiftWorkingTimeUpdatePayload;
  };
};

/**
 * AdminOrganizationUnit
 */
export type AdminOrganizationUnit = {
  admin_names: string[]; // ユニットの管理者名
  drivers_count: number; // ユニットの配達パートナー数
  id: number; // ユニットID
  name: string; // ユニット名
  recruitment_targets: OrganizationUnitRecruitmentTarget[]; // リクエストの配信対象
};

/**
 * AdminOrganizationUnitDetail
 */
export type AdminOrganizationUnitDetail = {
  admin_delivery_clients: {
    id: number;
    name: string;
  }[]; // ユニットの管理者
  driver_standby_shift_default_message_from_delivery_client?: string;
  id: number; // ユニットID
  member_delivery_clients: {
    id: number;
    name: string;
  }[]; // ユニットのメンバー
  name: string; // ユニット名
  recruitment_targets: OrganizationUnitRecruitmentTarget[]; // リクエストの配信対象
};

/**
 * AdminOrganizationUnitPage
 */
export type AdminOrganizationUnitPage = {
  organization_units: AdminOrganizationUnit[];
  pagination?: Pagination;
};

/**
 * AdminOrganizationUnitSimple
 */
export type AdminOrganizationUnitSimple = {
  id: number; // ユニットID
  name: string; // ユニット名
};

/**
 * ApiError
 */
export type ApiError = {
  error: {
    details: string[];
    status: number; // レスポンスのHTTPステータス
    title?: string;
    type:
      | "ApiError::RecordNotFound"
      | "ApiError::RecordInvalid"
      | "ApiError::Unauthorized"
      | "ApiError::BadRequest"
      | "ApiError::NewYearHolidays"
      | "ApiError::UnsupportedApiUserAgent"
      | "ApiError::Auth0::AccountNotFound"
      | "ApiError::Auth0::ExpiredIdToken"
      | "ApiError::Auth0::ExpiredAccessToken"
      | "ApiError::AzitAdmin::DeliveryRequest::IncreaseWaitingMinutesIsNotAllowed"
      | "ApiError::CurrentCapacity::InvalidMinutes"
      | "ApiError::DeliveryClient::AccountLocked"
      | "ApiError::DeliveryClient::FailedToSignUp"
      | "ApiError::DeliveryClient::OperationNotPermitted"
      | "ApiError::DeliveryClient::UsingOrganizationCreditCard"
      | "ApiError::DeliveryClient::UsingOrganizationInvoice"
      | "ApiError::DeliveryEntry::AlreadyEntered"
      | "ApiError::DeliveryEntry::DoubleBooking"
      | "ApiError::DeliveryEntry::ReachedEntryLimit"
      | "ApiError::DeliveryRequest::RecruitmentExpired"
      | "ApiError::DeliveryRequest::IsNotFinished"
      | "ApiError::DeliveryRequest::MatchedWithDriver"
      | "ApiError::DeliveryRequest::IsNotProxyArrangement"
      | "ApiError::DeliveryRequest::IsNotProxyArrangementFinished"
      | "ApiError::DeliveryRequest::CorrespondenceNotSupported"
      | "ApiError::DeliveryRequest::NotCancelable"
      | "ApiError::DeliveryRequest::Canceled"
      | "ApiError::DeliveryRequest::AlreadyFinished"
      | "ApiError::DeliveryRequest::TimeWindowNotOpened"
      | "ApiError::DeliveryRequest::TimeWindowUnsatisfiable"
      | "ApiError::DeliveryRequest::AccessibleDeliveryRequestTagsNotFound"
      | "ApiError::DeliveryRequest::AccessibleDriverSkillsNotFound"
      | "ApiError::DeliveryRequest::NetworkTypesEmpty"
      | "ApiError::DeliveryRequest::InvalidAssignMessageFromDeliveryClient"
      | "ApiError::Driver::FailedToSignUp"
      | "ApiError::Driver::Banned"
      | "ApiError::Driver::Deleting"
      | "ApiError::Driver::Deleted"
      | "ApiError::Driver::FailedToUpdateProfile"
      | "ApiError::Twilio::AccessToken::UnsupportedPlatform"
      | "ApiError::Geocoding::Failed"
      | "ApiError::OrganizationInvitation::MalformedImportCsv"
      | "ApiError::OrganizationInvitation::DeliveryClientAlreadyExists"
      | "ApiError::RegistrationByInvitation::InactiveInvitation"
      | "ApiError::DeliveryRequestDriverNomination::NotDriverNominationIndeterminate"
      | "ApiError::DriverStatus::NotNominated"
      | "ApiError::DriverStatus::NotWorking"
      | "ApiError::DriverStatus::NotInactive"
      | "ApiError::DriverStatus::UnsupportedTransition"
      | "ApiError::RoutePoint::WrongOrderDropOff"
      | "ApiError::RoutePoint::AlreadyDropOffFinished"
      | "ApiError::DriverStandbyShift::AlreadyCheckedIn"
      | "ApiError::DriverStandbyShift::BeforeCheckInTime"
      | "ApiError::DriverStandbyShift::InvalidCheckInDriverStatus"
      | "ApiError::DriverStandbyShift::NotYetCheckedIn"
      | "ApiError::DriverStandbyShift::AlreadyCheckedOut"
      | "ApiError::DriverStandbyShift::RequiredCheckedOutReason"
      | "ApiError::InHouseDriverImport::MalformedImportCsv"
      | "ApiError::DeliveryWork::NoCurrentDeliveryTaskError"
      | "ApiError::UpdateContractedDeliveryPartner::UpdateExclusiveStandbyDriverProfile"
      | "ApiError::UpdateContractedDeliveryPartner::UpdateError"
      | "ApiError::Tms::CheckIn::DriverStatusInvalid"
      | "ApiError::Tms::CheckIn::ShiftStatusInvalid"
      | "ApiError::Tms::CheckIn::AlreadyCheckedIn";
  };
};

/**
 * AzitAdminChargeBreakdown
 */
export type AzitAdminChargeBreakdown = ChargeBreakdown & {
  reason_for_waiting?: number;
  waiting_minutes?: number;
};

/**
 * AzitAdminDeliveryClient
 */
export type AzitAdminDeliveryClient = {
  email: string;
  id: number;
  name: string;
  phone_number: string;
};

/**
 * AzitAdminDeliveryClientDeliveryRequestV2
 */
export type AzitAdminDeliveryClientDeliveryRequestV2 = {
  delivery_client_charge: number; // 請求金額
  destination_names: string[]; // 配達先の名前
  distance_kilo_meter: number; // 配達距離
  id: number; // リクエストID
  pick_up_point_name: string; // 集荷先の名前
  publishable_after: string; // リクエストをpublish可能になる時刻
  published_at: string; // リクエストがpublishされた時刻
  status: AdminDeliveryRequestStatus;
};

/**
 * AzitAdminDeliveryClientOAuthClient
 */
export type AzitAdminDeliveryClientOAuthClient = {
  client_id: string;
};

/**
 * AzitAdminDeliveryClientPage
 */
export type AzitAdminDeliveryClientPage = {
  delivery_clients: AzitAdminDeliveryClient[];
  pagination: Pagination;
};

/**
 * AzitAdminDeliveryEntry
 */
export type AzitAdminDeliveryEntry = {
  delivery_request: {
    delivery_client_charge: number; // 請求金額
    delivery_client_name: string;
    destination_name: string; // 配達先の名前
    distance_kilo_meter: number; // 配達距離
    id: number; // リクエストID
    pick_up_point_name: string; // 集荷先の名前
    published_at: string; // 公開時刻
    status: AdminDeliveryRequestStatus;
  };
  id: number; // 応募ID
};

/**
 * AzitAdminDeliveryEntryPage
 */
export type AzitAdminDeliveryEntryPage = {
  delivery_entries: AzitAdminDeliveryEntry[];
  pagination: Pagination;
};

/**
 * AzitAdminDeliveryEntryPageV2
 */
export type AzitAdminDeliveryEntryPageV2 = {
  delivery_entries: AzitAdminDeliveryEntryV2[];
  pagination: Pagination;
};

/**
 * AzitAdminDeliveryEntryV2
 */
export type AzitAdminDeliveryEntryV2 = {
  delivery_request: {
    delivery_client_charge: number; // 請求金額
    delivery_client_name: string;
    destination_names: string[]; // 配達先
    distance_kilo_meter: number; // 配達距離
    id: number; // リクエストID
    pick_up_point_name: string; // 集荷先の名前
    published_at: string; // 公開時刻
    status: AdminDeliveryRequestStatus;
  };
  id: number; // 応募ID
};

/**
 * AzitAdminDeliveryRequestDetail
 */
export type AzitAdminDeliveryRequestDetail = {
  charge_breakdown?: AzitAdminChargeBreakdown;
  correspondence: boolean; // 信書便かどうか
  delivery_client: {
    email: string;
    id: number;
    name: string;
    phone_number: string;
  };
  delivery_client_charge_amount?: number; // 料金
  delivery_client_charge_status?: "in_progress" | "succeeded" | "failed"; // 決済状況
  delivery_entry?: {
    driver: {
      id: number;
      name: string;
      phone_number: string;
    };
    id: number;
  };
  destination_address: string; // 配達先の住所全体
  destination_name: string; // 配達先の名前
  destination_phone_number: string; // 配達先の電話番号
  distance_kilo_meter: number; // 配達距離
  finish_event_time_prediction?: EventTimePrediction;
  finished_at?: string; // 配達完了時刻
  id: number;
  matched_at?: string; // マッチ時刻
  message_from_delivery_client: string; // クライアントからの伝達事項
  organization_unit?: {
    id: number;
    name: string;
    organization_name: string;
  };
  pick_up_event_time_prediction?: EventTimePrediction;
  pick_up_point_address: string; // 集荷先の住所全体
  pick_up_point_name: string; // 集荷先の名前
  pick_up_point_phone_number: string; // 集荷先の電話番号
  proxy_arrangement_finished_event?: ProxyArrangementFinishedEvent;
  publishable_after: string; // リクエストをpublish可能になる時刻
  published_at: string; // リクエストがpublishされた時刻
  recruitment_finished_at?: string; // 募集終了時刻
  status: AdminDeliveryRequestStatus;
};

/**
 * AzitAdminDeliveryRequestDetailV2
 */
export type AzitAdminDeliveryRequestDetailV2 = {
  charge_breakdown?: AzitAdminChargeBreakdown;
  correspondence: boolean; // 信書便かどうか
  delivery_client: {
    email: string;
    id: number;
    name: string;
    phone_number: string;
  };
  delivery_client_charge_amount?: number; // 料金
  delivery_client_charge_status?: "in_progress" | "succeeded" | "failed"; // 決済状況
  delivery_entry?: {
    driver: {
      id: number;
      name: string;
      phone_number: string;
    };
    id: number;
  };
  delivery_request_tags: {
    id: number;
    name: string;
  }[];
  destinations: {
    address: string; // 配達先の住所全体
    event_time_prediction?: EventTimePrediction;
    message_from_delivery_client: string; // クライアントからの伝達事項
    name: string; // 配達先の名前
    phone_number?: string; // 配達先の電話番号
    pick_up_id?: string; // 注文番号
    task_status: RoutePointTaskStatus;
  }[];
  distance_kilo_meter: number; // 配達距離
  finished_at?: string; // 配達完了時刻
  id: number;
  marketplace_matched_delivery_network?: {
    delivery_price_amount: number;
    network_type: NetworkType;
  };
  matched_at?: string; // マッチ時刻
  matching_target: "marketplace" | "contracted_driver";
  message_from_delivery_client: string; // クライアントからの伝達事項
  organization_unit?: {
    id: number;
    name: string;
    organization_name: string;
  };
  pick_up_point: {
    address: string; // 集荷先の住所全体
    event_time_prediction?: EventTimePrediction;
    message_from_delivery_client: string; // クライアントからの伝達事項
    name: string; // 集荷先の名前
    phone_number?: string; // 集荷先の電話番号
  };
  proxy_arrangement_finished_event?: ProxyArrangementFinishedEvent;
  publishable_after: string; // リクエストをpublish可能になる時刻
  published_at: string; // リクエストがpublishされた時刻
  recruitment_finished_at?: string; // 募集終了時刻
  required_driver_skills: {
    id: number;
    name: string;
  }[];
  status: AdminDeliveryRequestStatus;
};

/**
 * AzitAdminDeliveryRequestDriverNomination
 */
export type AzitAdminDeliveryRequestDriverNomination = {
  created_at: string;
  deactivated_at: string;
  driver: {
    id: number;
    name: string;
  };
  id: number;
  status: "indeterminate" | "accepted" | "denied" | "canceled";
};

/**
 * AzitAdminDeliveryRequestDriverNominationsPage
 */
export type AzitAdminDeliveryRequestDriverNominationsPage = {
  delivery_request_driver_nominations: AzitAdminDeliveryRequestDriverNomination[];
  pagination: Pagination;
};

/**
 * AzitAdminDriver
 */
export type AzitAdminDriver = {
  email: string;
  full_furigana: string;
  full_name: string;
  id: number;
  phone_number: string;
};

/**
 * AzitAdminDriverPage
 */
export type AzitAdminDriverPage = {
  drivers: AzitAdminDriver[];
  pagination: Pagination;
};

/**
 * AzitAdminDriverProfile
 */
export type AzitAdminDriverProfile = {
  email: string;
  family_name: string; // 姓
  family_name_furigana: string; // 姓の平仮名のふりがな
  given_name: string; // 名
  given_name_furigana: string; // 名の平仮名のふりがな
  id: number;
  phone_number: string; // 電話番号
};

/**
 * AzitAdminDriverProfileDetail
 */
export type AzitAdminDriverProfileDetail = {
  account_delete_request: {
    id: number;
    status: "not_yet" | "done" | "canceled";
  } | null;
  email: string;
  family_name: string; // 姓
  family_name_furigana: string; // 姓の平仮名のふりがな
  given_name: string; // 名
  given_name_furigana: string; // 名の平仮名のふりがな
  id: number;
  is_deleted: boolean;
  phone_number: string; // 電話番号
  skills: {
    id: number;
    is_general: boolean;
    name: string;
  }[];
};

/**
 * 集合場所
 */
export type AzitAdminDriverStandbyArea = {
  coordinates?: Coordinates;
  id: number;
  name: string;
  note?: string; // 集合場所に関する詳細メモ
};

/**
 * AzitAdminDriverStandbyAreaCreatePayload
 */
export type AzitAdminDriverStandbyAreaCreatePayload = {
  driver_standby_area: {
    coordinates?: Coordinates;
    name: string;
    note?: string;
    organization_unit_id?: number;
  };
};

/**
 * AzitAdminDriverStandbyAreaPage
 */
export type AzitAdminDriverStandbyAreaPage = {
  driver_standby_areas: AzitAdminDriverStandbyArea[];
  pagination: Pagination;
};

/**
 * AzitAdminDriverStandbyAreaUpdatePayload
 */
export type AzitAdminDriverStandbyAreaUpdatePayload = {
  driver_standby_area: {
    coordinates?: Coordinates;
    name?: string;
    note?: string;
  };
};

/**
 * AzitAdminGoogleOAuthIdTokenAttributes
 */
export type AzitAdminGoogleOAuthIdTokenAttributes = {
  email: string;
  name: string;
};

/**
 * AzitAdminGoogleOAuthUrl
 */
export type AzitAdminGoogleOAuthUrl = {
  url: string;
};

/**
 * AzitAdminMonthlyPayoutToDriverRakutenTotalPaymentsPage
 */
export type AzitAdminMonthlyPayoutToDriverRakutenTotalPaymentsPage = {
  monthly_payout_to_driver_rakuten_total_payments: MonthlyPayoutToDriverRakutenTotalPaymentsPage[];
  pagination: Pagination;
};

/**
 * AzitAdminOrganizationPaymentMethod
 */
export type AzitAdminOrganizationPaymentMethod = {
  type: "credit_card" | "invoice"; // 決済手段の種類
};

/**
 * AzitAdminOrganizationsPage
 */
export type AzitAdminOrganizationsPage = {
  organizations: Organization[];
  pagination: Pagination;
};

/**
 * Bank
 */
export type Bank = {
  id: number;
  name: string; // 銀行名
};

/**
 * BankAccount
 */
export type BankAccount = {
  account_name: BankAccountName;
  account_number: string; // 7桁の口座番号
  account_type: "normal" | "checking" | "deposit"; // 口座タイプ
  bank: Bank;
  branch_code: string; // 3桁の支店番号
};

/**
 * BankAccountName
 */
export type BankAccountName = {
  family_name: string; // 名義人名の姓
  given_name: string; // 名義人名の名前
};

/**
 * 料金明細
 */
export type ChargeBreakdown = {
  basic_charge: number; // 基本料金
  delivery_distance_charge: number; // 距離に応じた料金
  dynamic_pricing_charge?: number | null; // ダイナミックプライシング料金
  dynamic_pricing_rate: number; // ダイナミックプライシング率
  premium_matching_charge?: number | null; // プレミアムマッチング料金
  tax_charge: number | null; // 税
  waiting_time_charge?: number | null; // 停車料金
};

/**
 * 料金計算
 */
export type ChargeBreakdownEstimate = {
  basic_charge: number; // 基本料金
  delivery_distance_charge: number; // 距離に応じた料金
  distance_kilo_meter: number; // 距離
  dynamic_pricing_charge: number; // ダイナミックプライシング料金
  dynamic_pricing_rate: number; // ダイナミックプライシング率
  tax_charge: number; // 税
};

/**
 * ContractedDeliveryPartnersCurrentLocation
 */
export type ContractedDeliveryPartnersCurrentLocation = {
  active_delivery_request?: {
    id: number;
    route_points: {
      address: string;
      done_at?: string; // 集荷 or 配達が完了した時刻
      drop_off_pick_up_id?: string; // その地点で配達する配達物のID
      event_time_prediction: EventTimePrediction;
      id: number;
      name: string;
      pick_up_ids?: string[]; // その地点で集荷する集荷物のIDリスト
      task_status: RoutePointTaskStatus;
      task_type: "pick_up" | "drop_off";
    }[];
  };
  active_driver_standby_shift?: {
    finished_at: string; // シフトの終了時刻
    started_at: string; // シフトの開始時刻
  };
  delivery_partner: {
    contract_type: "in_house_delivery_partner" | "exclusive_standby"; // 契約形態 in_house_delivery_partner: 自社配達パートナー exclusive_standby: 専属待機
    coordinate: {
      latitude: number; // 緯度
      longitude: number; // 経度
    }; // 配達パートナーの現在位置
    id: number; // 配達パートナーのID
    profile: {
      family_name: string; // 姓
      given_name: string; // 名
      phone_number: string; // 電話番号
    };
  };
}[];

/**
 * 契約済み配達パートナー
 */
export type ContractedDriver = {
  contracted_type: "in_house_delivery_partner" | "exclusive_standby"; // in_house_delivery_partnerは自社雇用  exclusive_standbyは専属待機
  driver_standby_shift: {
    finished_at: string;
    started_at: string;
  };
  email: string;
  full_name: string;
  id: number;
  is_inviting: boolean; // 招待中かどうか
  organization_units: AdminOrganizationUnitSimple[];
  phone_number: string;
  skills: {
    id: number;
    name: string;
  }[];
  status: DriverStatus;
};

/**
 * ContractedDriverDetail
 */
export type ContractedDriverDetail = {
  contracted_type: "in_house_delivery_partner" | "exclusive_standby"; // in_house_delivery_partnerは自社雇用  exclusive_standbyは専属待機
  driver_standby_shift: {
    finished_at: string;
    started_at: string;
  };
  email: string;
  family_name: string; // 姓
  family_name_furigana: string; // 姓の平仮名のふりがな
  given_name: string; // 名
  given_name_furigana: string; // 名の平仮名のふりがな
  id: number;
  is_inviting: boolean; // 招待中かどうか
  organization_units: AdminOrganizationUnitSimple[];
  phone_number: string;
  skills: {
    id: number;
    is_general: boolean;
    name: string;
  }[];
  status: DriverStatus;
};

/**
 * ContractedDriverPage
 */
export type ContractedDriverPage = {
  drivers: ContractedDriver[];
  pagination: Pagination;
};

/**
 * 緯度経度
 */
export type Coordinates = {
  latitude: string; // 緯度
  longitude: string; // 経度
};

/**
 * DeliveryClient
 */
export type DeliveryClient = {
  email: string; // メールアドレス
  id: number;
  name: string; // 事業者名
  organization?: {
    id: number;
    name: string;
    payment_method: "credit_card" | "invoice";
    proxy_arrangement_enabled: boolean; // 代理手配が有効かどうか
    role: string; // 所属ロール
  }; // 所属している企業アカウントが存在する時
  phone_number: string; // 電話番号
};

/**
 * クライアントの決済情報
 */
export type DeliveryClientCharge = {
  amount: number;
  payment_method:
    | "credit_card"
    | "organization_credit_card"
    | "organization_invoice";
  status: "in_progress" | "succeeded" | "failed";
  stripe_source?: StripeSource;
};

/**
 * DeliveryClientEmailAvailability
 */
export type DeliveryClientEmailAvailability = {
  availability: boolean;
};

/**
 * DeliveryClientOAuthAccessToken
 */
export type DeliveryClientOAuthAccessToken = {
  expired_at: string; // アクセストークンの有効期限
  token: string; // アクセストークン
};

/**
 * DeliveryEntry
 */
export type DeliveryEntry = {
  distance_kilo_meter: number; // 配達距離
  id: number;
};

/**
 * DeliveryNetworkAvailability
 */
export type DeliveryNetworkAvailability = {
  is_available: boolean; // 配送可否
  minimum_amount?: number; // 最低料金
  network_type: NetworkType;
  not_available_reason?: "OverMaximumAmount" | "OutOfArea" | "InternalError"; // 配送不可能な理由
};

/**
 * DeliveryPartnerInhouseDriverShift
 */
export type DeliveryPartnerInhouseDriverShift = {
  area_name: string;
  assigned: boolean; // 自社配達員シフトが自分に割り当てられているかどうか
  check_in_buffer_minutes: number;
  check_in_status: InhouseDriverShiftCheckinStatus;
  finished_at?: string;
  id: number;
  message_from_delivery_client: string;
  organization_unit_name: string;
  started_at?: string;
};

/**
 * DeliveryPartnerInhouseDriverShiftPage
 */
export type DeliveryPartnerInhouseDriverShiftPage = {
  inhouse_driver_shifts: DeliveryPartnerInhouseDriverShift[];
  pagination: Pagination;
};

/**
 * DeliveryRequest
 */
export type DeliveryRequest = {
  destination_address: string; // 配達先
  distance_kilo_meter: number; // 配達距離
  id: number;
  message_from_delivery_client: string; // クライアントからの伝達事項
  pay_of_delivery: number; // 報酬
  pick_up_point_address: string; // 集荷先
  recruitment_finished_at?: string; // 募集終了時刻
};

/**
 * DeliveryRequestDetail
 */
export type DeliveryRequestDetail = DeliveryRequest & {
  destination_latitude: string; // 配達先の緯度
  destination_longitude: string; // 配達先の経度
  pick_up_point_latitude: string; // 集荷先の緯度
  pick_up_point_longitude: string; // 集荷先の経度
  status: DriverDeliveryRequestStatus;
};

/**
 * DeliveryRequestDriverNomination
 */
export type DeliveryRequestDriverNomination = {
  deactivated_at?: string; // 指名の有効期限の日時
  destination_address: string; // 配達先
  destination_latitude: string; // 配達先の緯度
  destination_longitude: string; // 配達先の経度
  destination_time_window?: TimeWindow;
  distance_kilo_meter: number; // 配達距離
  id: number;
  message_from_delivery_client: string; // クライアントからの伝達事項
  pay_of_delivery: number; // 報酬
  pick_up_point_address: string; // 集荷先
  pick_up_point_latitude: string; // 集荷先の緯度
  pick_up_point_longitude: string; // 集荷先の経度
  pick_up_time_window?: TimeWindow;
  published_at: string; // 指名が発行された日時
  recruitment_finished_at?: string; // 募集終了時刻
  status: DriverDeliveryRequestStatus;
};

/**
 * DeliveryRequestDriverNominationV2
 */
export type DeliveryRequestDriverNominationV2 = {
  deactivated_at?: string; // 指名の有効期限の日時
  destinations: {
    address: string; // 配達先
    coordinates: {
      latitude: string; // 配達先の緯度
      longitude: string; // 配達先の経度
    };
    time_window?: TimeWindow;
  }[];
  distance_kilo_meter: number; // 配達距離
  estimated_delivery_work_complete_at?: string; // 配達業務終了時刻予測
  id: number;
  is_additional_request?: boolean; // 追加のリクエストか 自身のリクエストのみ値が存在する
  message_from_delivery_client: string; // クライアントからの伝達事項
  pay_of_delivery: number; // 報酬
  pick_up_point: {
    address: string; // 集荷先
    coordinates: {
      latitude: string; // 集荷先の緯度
      longitude: string; // 集荷先の経度
    };
    time_window?: TimeWindow;
  };
  published_at: string; // 指名が発行された日時
  recruitment_finished_at?: string; // 募集終了時刻
  status: DriverDeliveryRequestStatus;
};

/**
 * DeliveryRequestTag
 */
export type DeliveryRequestTag = {
  description: string; // タグの説明
  id: number; // タグID
  name: string; // タグ名
};

/**
 * DeliveryRequestTagPage
 */
export type DeliveryRequestTagPage = {
  delivery_request_tags: DeliveryRequestTag[];
  pagination: Pagination;
};

/**
 * DeliveryRequestV2
 */
export type DeliveryRequestV2 = {
  destination_addresses: string[]; // 配達先
  distance_kilo_meter: number; // 配達距離
  id: number;
  message_from_delivery_client: string; // クライアントからの伝達事項
  pay_of_delivery: number; // 報酬
  pick_up_point_address: string; // 集荷先
  recruitment_finished_at?: string; // 募集終了時刻
};

/**
 * 配達業務
 */
export type DeliveryWork = {
  delivery_work_tasks: DeliveryWorkTask[]; // おこなうタスク
  driver_id: number; // タスクをおこなうドライバーのID
  id: number;
  message_from_delivery_client?: string; // 現在のタスクへのクライアントからの伝達事項
  phone_number?: string; // 現在のタスクの電話の電話番号
  requester_name: string; // 現在のタスクの依頼者名
  starts_at: string; // 業務を開始した時間
};

/**
 * 業務の各タスク
 */
export type DeliveryWorkTask = {
  id: number;
  order: number; // タスクをおこなう順番
  route_point_id: number; // タスクをおこなう地点のID
  status: RoutePointTaskStatus;
  type: TaskType;
};

/**
 * 業務の各タスク
 */
export type DeliveryWorkTaskDetail = {
  address: string;
  coordinates: {
    latitude: string; // 緯度
    longitude: string; // 経度
  }; // タスクを実施する場所の緯度経度
  delivery_work_id: number;
  drop_off_pick_up_id: string;
  event_time_prediction: EventTimePrediction;
  id: number;
  is_current_task: boolean;
  message_from_delivery_client: string;
  order: number; // タスクをおこなう順番
  permissible_delay_minutes: number; // 許容される配達完了の遅延分数
  phone_number?: string;
  pick_up_ids: string[];
  requester_name: string;
  route_point_id: number; // タスクをおこなう地点のID
  status: RoutePointTaskStatus;
  time_window?: TimeWindow;
  type: TaskType;
};

/**
 * DriverAccountDeleteRequest
 */
export type DriverAccountDeleteRequest = {
  contact_email: string;
  driver: {
    family_name: string;
    given_name: string;
    id: number;
  };
  id: number;
  status: "not_yet" | "done" | "canceled";
};

/**
 * DriverAccountDeleteRequestPage
 */
export type DriverAccountDeleteRequestPage = {
  driver_account_delete_requests: DriverAccountDeleteRequest[];
  pagination: Pagination;
};

/**
 * DriverBan
 */
export type DriverBan = {
  created_at: string;
  expired_at: string;
  id: number;
};

/**
 * DriverDeliveryRequestStatus
 */
export type DriverDeliveryRequestStatus =
  | "published"
  | "go_to_pick_up"
  | "in_delivery"
  | "canceled"
  | "finished"
  | "proxy_arrangement"
  | "proxy_arrangement_finished"
  | "reserved"
  | "buffered";

/**
 * DriverDevice
 */
export type DriverDevice = {
  device_id: string; // 配達パートナーのdevice_id
  id: number;
};

/**
 * DriverProfile
 */
export type DriverProfile = {
  email: string;
  family_name: string; // 姓
  family_name_furigana: string; // 姓の平仮名のふりがな
  given_name: string; // 名
  given_name_furigana: string; // 名の平仮名のふりがな
  id: number;
  this_month_payout: number; // 今月の給与
};

/**
 * DriverProfileUpdateRequestBody
 */
export type DriverProfileUpdateRequestBody = {
  driver: {
    family_name?: string;
    family_name_furigana?: string;
    given_name?: string;
    given_name_furigana?: string;
  };
};

/**
 * DriverSkill
 */
export type DriverSkill = {
  description: string; // スキルの説明
  id: number; // スキルID
  is_general: boolean; // 全クライアントから利用可能な配達パートナースキルかどうか
  name: string; // スキル名
};

/**
 * DriverSkillPage
 */
export type DriverSkillPage = {
  driver_skills: DriverSkill[];
  pagination: Pagination;
};

/**
 * DriverStandbyArea
 */
export type DriverStandbyArea = {
  id: number;
  name: string;
};

/**
 * DriverStandbyShift
 */
export type DriverStandbyShift = {
  check_in_status?: "not_yet" | "checked_in" | "checked_out";
  driver: {
    hubspot_contact_id?: number;
    id: number;
    name: string;
  };
  driver_standby_area: {
    id: number;
    name: string;
  };
  id: number;
  message_from_delivery_client?: string;
  organization_unit?: {
    id: number;
    name: string;
    organization_name: string;
  };
} & ShiftWorkingTime;

/**
 * DriverStandbyShiftDetail
 */
export type DriverStandbyShiftDetail = {
  area_name: string;
  check_in_buffer_minutes: number;
  check_in_status: "not_yet" | "checked_in" | "checked_out";
  finished_at?: string;
  id: number;
  message_from_delivery_client: string;
  organization_unit_name: string;
  started_at?: string;
} & ShiftWorkingTime;

/**
 * DriverStatus
 */
export type DriverStatus = {
  status: "inactive" | "waiting_request" | "nominated" | "working"; // 配達パートナーの現在の運行ステータス
};

/**
 * DynamicPricingRateEntry
 */
export type DynamicPricingRateEntry = {
  bad_weather_rate: number; // 平日の悪天候時のDP係数
  holiday_bad_weather_rate: number; // 土日・祝日の悪天候時のDP係数
  holiday_rate: number; // 土日・祝日の通常天候時のDP係数
  hour: number; // 24時間制のHOUR
  normal_rate: number; // 平日の通常天候時のDP係数
  wday: number; // 曜日
};

/**
 * EventTimePrediction
 */
export type EventTimePrediction = {
  event_type?: "pick_up" | "finish";
  latest_prediction: string; // 最新の予測
  original_prediction: string; // 当初の予測
};

/**
 * FailedDeliveryClientChargePageV2
 */
export type FailedDeliveryClientChargePageV2 = {
  failed_delivery_client_charges: FailedDeliveryClientChargeV2[];
  pagination: Pagination;
};

/**
 * FailedDeliveryClientChargeV2
 */
export type FailedDeliveryClientChargeV2 = {
  amount: number;
  delivery_request: {
    destination_route_point_name?: string[];
    id: number;
    pick_up_route_point_name: string;
    published_at: string;
  };
  id: number;
};

/**
 * FamilyNameGivenName
 */
export type FamilyNameGivenName = {
  family_name: string;
  given_name: string;
};

/**
 * InHouseDriverImportBatch
 */
export type InHouseDriverImportBatch = {
  created_at: string; // 作成時刻
  delivery_client_name: string; // バッチを入稿したクライアント名
  file_name: string; // 入稿時のファイル名
  id: number; // バッチのID
  status: "in_progress" | "succeeded" | "failed"; // 処理状況
};

/**
 * InHouseDriverImportBatchEntry
 */
export type InHouseDriverImportBatchEntry = {
  family_name: string; // 姓
  family_name_furigana: string; // ふりがな (姓)
  given_name: string; // 名
  given_name_furigana: string; // ふりがな (名)
  id: number; // バッチのID
  organization_units: {
    name: string; // ユニット名
  }[];
  phone_number: string; // 電話番号
  registration_status: "enqueued" | "succeeded" | "failed"; // 登録ステータス
};

/**
 * InhouseDriverShiftCheckinStatus
 */
export type InhouseDriverShiftCheckinStatus =
  | "not_yet"
  | "checked_in"
  | "checked_out";

/**
 * 緯度経度情報
 */
export type LatLng = {
  latitude: number; // 緯度
  longitude: number; // 緯度
};

/**
 * MonthlyPayoutToDriverRakutenTotalPaymentsPage
 */
export type MonthlyPayoutToDriverRakutenTotalPaymentsPage = {
  amount: number;
  execution_time_with_zone: string;
  id: number;
  month: number;
  year: number;
};

/**
 * 配送ネットワーク
 */
export type NetworkType = "crew_express_one_time" | "wolt" | "menu";

/**
 * 年末年始サービス休止期間
 */
export type NewYearHolidays = {
  finish_at: string; // 休止期間終了時刻
  start_at: string; // 休止期間開始時刻
  status: "active" | "inactive"; // 現在が休止期間かどうか active: 休止期間ではない inactive: 休止期間中
};

/**
 * 営業時間外の時間帯
 */
export type NonBusinessHours = {
  close_hour: number; // 営業が終了する時刻24h表記
  open_hour: number; // 営業が開始する時刻24h表記
};

/**
 * Organization
 */
export type Organization = {
  id: number;
  name: string;
  payment_method: "credit_card" | "invoice";
  proxy_arrangement_enabled: boolean; // 代理手配が有効かどうか
  service_plan: "platform" | "exclusive"; // 契約形態 platform: プラットフォームプラン(デフォルト) exclusive: 専属契約
};

/**
 * OrganizationDeliveryRequestV2
 */
export type OrganizationDeliveryRequestV2 = {
  delivery_client: DeliveryClient;
  delivery_client_charge: number; // 請求金額
  destination_names: string[]; // 配達先の名前
  distance_kilo_meter: number; // 配達距離
  id: number; // リクエストID
  pick_up_point_name: string; // 集荷先の名前
  pricing_model?: PricingModel;
  publishable_after: string; // リクエストをpublish可能になる時刻
  published_at: string; // リクエストがpublishされた時刻
  status: AdminDeliveryRequestStatus;
};

/**
 * OrganizationInvitation
 */
export type OrganizationInvitation = {
  email: string;
  name: string;
  role: "admin" | "member";
};

/**
 * OrganizationMembership
 */
export type OrganizationMembership = {
  delivery_client_id: number;
  email: string;
  name: string;
  role: "admin" | "member";
};

/**
 * OrganizationMembershipPage
 */
export type OrganizationMembershipPage = {
  organization_memberships: OrganizationMembership[];
  pagination?: Pagination;
};

/**
 * OrganizationPaymentMethod
 */
export type OrganizationPaymentMethod = {
  credit_card?: StripeSource;
  type: "credit_card" | "invoice"; // 決済手段の種類
};

/**
 * OrganizationUnit
 */
export type OrganizationUnit = {
  driver_standby_shift_default_message_from_delivery_client?: string;
  id: number; // ユニットID
  name: string; // ユニット名
  organization: {
    id: number;
    name: string;
  };
  recruitment_targets: OrganizationUnitRecruitmentTarget[]; // リクエストの配信対象
};

/**
 * OrganizationUnitPage
 */
export type OrganizationUnitPage = {
  organization_units: OrganizationUnit[];
  pagination: Pagination;
};

/**
 * OrganizationUnitRecruitmentTarget
 */
export type OrganizationUnitRecruitmentTarget = {
  display_name: string;
  type: "platform" | "exclusive_standby";
};

/**
 * Pagination
 */
export type Pagination = {
  current_page: number; // 現在のページ番号
  next_page?: number | null; // 次のページ番号、 存在しない場合はnil
  per_page: number; // 1ページあたりのエントリ数
  previous_page?: number | null; // 前のページ番号、 存在しない場合はnil
  total_pages: number; // ページ総数
};

/**
 * PaymentMethod
 */
export type PaymentMethod = {
  credit_card?: StripeSource;
  type: "credit_card" | "organization_credit_card" | "organization_invoice"; // 決済手段の種類
};

/**
 * Payslip
 */
export type Payslip = {
  amount: number; // 給与総額
  id: number; // 給与明細のID
  pay_period: {
    finish: string; // DateTimeの形式になっていますので、月と日を利用してください
    start: string; // DateTimeの形式になっていますので、月と日を利用してください
  }; // 業務日
  payment_date: string; // 給料日 DateTimeの形式になっていますので、月と日を利用してください
};

/**
 * PayslipDeliveryEntry
 */
export type PayslipDeliveryEntry = {
  amount: number; // 給与
  delivery_finished_at: string; // 配達完了時刻
} & DeliveryEntry;

/**
 * クライアントがおこうことができるパーミッション一覧
 */
export type Permission = {
  delivery_partner: {
    create: boolean;
    update: boolean;
  };
  delivery_request_tag: {
    archive: boolean;
    create: boolean;
    list: boolean;
    update: boolean;
  };
  driver_skill: {
    archive: boolean;
    create: boolean;
    update: boolean;
  };
  in_house_driver_import_batch: {
    create: boolean;
    list: boolean;
    show: boolean;
  };
  organization_member: {
    list: boolean;
  };
  unit: {
    create: boolean;
    show: {
      type: "all_units" | "only_belongs_units"; // 閲覧権限の種類
      unit_ids?: number[]; // typeがall_units以外の場合にオプションとして加わる
    };
    update: boolean;
  };
};

/**
 * PhoneNumberVerificationCode
 */
export type PhoneNumberVerificationCode = {
  phone_number: string;
  uuid: string;
  verified_at: string;
};

/**
 * PickUpTimePrediction
 */
export type PickUpTimePrediction = {
  prediction_minutes?: number; // おおよその予想集荷時間
};

/**
 * リクエストの料金体系
 */
export type PricingModel =
  | "crew_express_one_time"
  | "crew_express_exclusive_standby"
  | "in_house_driver";

/**
 * ProxyArrangementFinishedEvent
 */
export type ProxyArrangementFinishedEvent = {
  finished_at: string;
  message_from_azit: string; // 依頼代行の共有事項
};

/**
 * PushConfiguration
 */
export type PushConfiguration = {
  enabled: boolean; // Push通知が有効になっているかどうか
};

/**
 * ReportDropOffResult
 */
export type ReportDropOffResult = {
  delivery_payment: number; // 案件の報酬額  完了していない場合はnull
  next_delivery_work_task_id: number; // 次に向かうタスクのID  すべてのタスクを完了している場合はnull
};

/**
 * ReportPickUpResult
 */
export type ReportPickUpResult = {
  next_delivery_work_task_id: number; // 次に向かうタスクのID  すべてのタスクを完了している場合はnull
};

/**
 * RoutePointHistory
 */
export type RoutePointHistory = {
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  id: number;
  message_from_delivery_client?: string;
  name: string;
  phone_number: string;
};

/**
 * RoutePointTaskStatus
 */
export type RoutePointTaskStatus = "not_yet" | "done";

/**
 * ServiceCapacity
 */
export type ServiceCapacity = {
  prediction_minutes?: number; // おおよその予想集荷時間
};

/**
 * ShiftWorkingTime
 */
export type ShiftWorkingTime = {
  finished_at: string;
  started_at: string;
};

/**
 * ShiftWorkingTimeUpdatePayload
 */
export type ShiftWorkingTimeUpdatePayload = {
  finished_at?: string;
  started_at?: string;
};

/**
 * StripePublicKey
 */
export type StripePublicKey = {
  stripe_public_key: string;
};

/**
 * StripeSource
 */
export type StripeSource = {
  brand:
    | "American Express"
    | "Diners Club"
    | "Discover"
    | "JCB"
    | "MasterCard"
    | "UnionPay"
    | "Visa"
    | "Unknown"; // カードのブランド名
  exp_month: number; // 有効期限の月
  exp_year: number; // 有効期限の年
  last4: number; // カード番号の末尾4桁
  name?: string | null; // 名前
};

/**
 * TaskType
 */
export type TaskType = "pick_up" | "drop_off";

/**
 * TemporaryDynamicPricingRate
 */
export type TemporaryDynamicPricingRate = {
  ending_at: string; // 上書きDP係数の有効期限
  rate: number; // 一時的に設定するDPの係数
};

/**
 * 時間帯指定
 */
export type TimeWindow = {
  close_at: string;
  open_at: string;
};

/**
 * VerificationStatus
 */
export type VerificationStatus = {
  status: "verified" | "being_verified" | "unverified"; // 配達パートナーの審査のステータス
};

/**
 * 現在のDeliveryClientの情報取得
 */
export const getAdminAccount = (
  params: InputArguments = {}
): Promise<TypedResponse<DeliveryClient>> => {
  return request({
    method: `GET`,
    url: `/admin/account`,
    params,
  });
};

/**
 * 現在のDeliveryClientの情報更新
 */
export const patchAdminAccount = (
  params: InputArguments & {
    body?: {
      profile: {
        email?: string;
        password?: string;
        phone_number?: string;
      };
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/admin/account`,
    params,
  });
};

/**
 * クライアントがおこなうことができるパーミッションを取得する
 */
export const getAdminAccountPermission = (
  params: InputArguments = {}
): Promise<TypedResponse<Permission>> => {
  return request({
    method: `GET`,
    url: `/admin/account/permission`,
    params,
  });
};

/**
 * リクエストをキャンセルするAPI
 */
export const postAdminApiV1DeliveryRequestsCanceleds = (
  params: InputArguments & {
    body?: {
      delivery_request: {
        id: number; // リクエストID
      };
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/admin/api/v1/delivery_requests/canceleds`,
    params,
  });
};

/**
 * 予測される集荷までの時間
 */
export const getAdminApiV1PickUpTimePrediction = (
  params: InputArguments & {
    query?: {
      organization_unit_id?: number;
    };
  } = {}
): Promise<TypedResponse<PickUpTimePrediction>> => {
  return request({
    method: `GET`,
    url: `/admin/api/v1/pick_up_time_prediction`,
    params,
  });
};

/**
 * 配送可能なネットワークの一覧を取得
 */
export const postAdminApiV3MarketplaceDeliveryNetworkAvailabilities = (
  params: InputArguments & {
    body?: {
      delivery_request: {
        destination: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
        };
        maximum_amount?: number; // 上限金額
        pick_up_point: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
        };
      };
    };
  } = {}
): Promise<
  TypedResponse<{
    delivery_network_availabilities: DeliveryNetworkAvailability[];
  }>
> => {
  return request({
    method: `POST`,
    url: `/admin/api/v3/marketplace/delivery_network_availabilities`,
    params,
  });
};

/**
 * リクエスト入稿
 */
export const postAdminApiV3MarketplaceDeliveryRequests = (
  params: InputArguments & {
    body?: {
      delivery_request: {
        delivery_request_tag_ids?: number[]; // リクエストタグIDの配列
        destinations: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
          message_from_delivery_client?: string; // 集荷先での伝達事項
          name: string; // 集荷場所の名称
          phone_number?: string; // 集荷場所の電話番号
          time_window?: {
            close_at: string; // 配達時間帯終了時刻
            open_at: string; // 配達時間帯開始時刻
          }; // 配達時間帯
        }[];
        maximum_amount: number; // 上限金額
        message_from_delivery_client: string; // クライアントからの伝達事項
        network_types: NetworkType[];
        organization_unit_id?: number; // リクエストの属するユニット
        pick_up_point: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
          message_from_delivery_client?: string; // 集荷先での伝達事項
          min_preparation_time_minutes: number; // 集荷物の準備にかかる時間
          name: string; // 集荷場所の名称
          phone_number?: string; // 集荷場所の電話番号
        };
      };
    };
  } = {}
): Promise<
  TypedResponse<{
    id: number; // リクエストのID
  }>
> => {
  return request({
    method: `POST`,
    url: `/admin/api/v3/marketplace/delivery_requests`,
    params,
  });
};

/**
 * アクセストークン取得
 */
export const postAdminApiV3OAuthAccessTokens = (
  params: InputArguments & {
    body?: {
      o_auth_access_token: {
        client_id: string; // クライアントID
        client_secret: string; // クライアントシークレット
      };
    };
  } = {}
): Promise<TypedResponse<DeliveryClientOAuthAccessToken>> => {
  return request({
    method: `POST`,
    url: `/admin/api/v3/o_auth_access_tokens`,
    params,
  });
};

/**
 * リクエスト入稿
 */
export const postAdminApiV3TmsDeliveryRequests = (
  params: InputArguments & {
    body?: {
      delivery_request: {
        delivery_request_tag_ids?: number[]; // リクエストタグIDの配列
        destinations: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
          message_from_delivery_client?: string; // 集荷先での伝達事項
          name: string; // 集荷場所の名称
          phone_number?: string; // 集荷場所の電話番号
          time_window?: {
            close_at: string; // 配達時間帯終了時刻
            open_at: string; // 配達時間帯開始時刻
          }; // 配達時間帯
        }[];
        driver_skill_ids?: number[]; // 要求する配達パートナースキルIDの配列
        message_from_delivery_client: string; // クライアントからの伝達事項
        optimize_route?: boolean; // ルート最適化を実施するか
        organization_unit_id: number; // リクエストの属するユニット
        pick_up_point: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
          message_from_delivery_client?: string; // 集荷先での伝達事項
          name: string; // 集荷場所の名称
          phone_number?: string; // 集荷場所の電話番号
        };
      };
    };
  } = {}
): Promise<
  TypedResponse<{
    id: number; // リクエストのID
  }>
> => {
  return request({
    method: `POST`,
    url: `/admin/api/v3/tms/delivery_requests`,
    params,
  });
};

/**
 * 契約済み配達パートナーの現在の位置情報・稼動状況等を取得
 */
export const getAdminContractedDeliveryPartnersCurrentLocation = (
  params: InputArguments = {}
): Promise<TypedResponse<ContractedDeliveryPartnersCurrentLocation>> => {
  return request({
    method: `GET`,
    url: `/admin/contracted_delivery_partners_current_location`,
    params,
  });
};

/**
 * 契約済み配達パートナー一覧
 */
export const getAdminContractedDrivers = (
  params: InputArguments & {
    query?: {
      contract_type?: "in_house" | "exclusive_standby";
      organization_unit_id?: number;
      page?: number;
      per_page?: number;
      search?: {
        full_name_cont?: string;
      };
    };
  } = {}
): Promise<TypedResponse<ContractedDriverPage>> => {
  return request({
    method: `GET`,
    url: `/admin/contracted_drivers`,
    params,
  });
};

/**
 * 配達パートナー詳細
 */
export const getAdminContractedDriversWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<ContractedDriverDetail>> => {
  return request({
    method: `GET`,
    url: `/admin/contracted_drivers/${params.path?.id}`,
    params,
  });
};

/**
 * 配達パートナー更新
 */
export const putAdminContractedDriversWithId = (
  params: InputArguments & {
    body?:
      | {
          profile: {
            email: string;
            family_name: string;
            family_name_furigana: string;
            given_name: string;
            given_name_furigana: string;
            organization_unit_ids: number[]; // アサイン権限がないIDの場合はエラーになる
            phone_number: string;
            skill_ids: number[];
          };
        }
      | {
          profile?: {
            skill_ids: number[]; // Express共通のIDを指定したらエラーになる
          };
        };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/admin/contracted_drivers/${params.path?.id}`,
    params,
  });
};

/**
 * クライアント新規登録
 */
export const postAdminDeliveryClients = (
  params: InputArguments & {
    body?: {
      delivery_client: {
        email: string; // メールアドレス
        name: string; // 名前
        password: string; // パスワード
        phone_number: string; // 電話番号
      };
    };
  } = {}
): Promise<TypedResponse<DeliveryClient>> => {
  return request({
    method: `POST`,
    url: `/admin/delivery_clients`,
    params,
  });
};

/**
 * メールアドレスの有効チェック
 */
export const postAdminDeliveryClientsEmailAvailability = (
  params: InputArguments & {
    body?: {
      delivery_client: {
        email: string; // 有効性をチェックしたいメールアドレス
      };
    };
  } = {}
): Promise<TypedResponse<DeliveryClientEmailAvailability>> => {
  return request({
    method: `POST`,
    url: `/admin/delivery_clients/email_availability`,
    params,
  });
};

/**
 * AdminやAzit側の招待後のクライアント新規登録
 */
export const postAdminDeliveryClientsRegistrationByInvitation = (
  params: InputArguments & {
    body?: {
      delivery_client: {
        invitation_link_uuid: string; // invitationのリンク用のUUID
        password: string; // パスワード
        phone_number: string; // 電話番号
      };
    };
  } = {}
): Promise<TypedResponse<DeliveryClient>> => {
  return request({
    method: `POST`,
    url: `/admin/delivery_clients/registration_by_invitation`,
    params,
  });
};

/**
 * postAdminDeliveryClientsSessions
 */
export const postAdminDeliveryClientsSessions = (
  params: InputArguments & {
    body?: {
      sessions: {
        email: string;
        password: string;
        remember_me: boolean;
      };
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/admin/delivery_clients/sessions`,
    params,
  });
};

/**
 * deleteAdminDeliveryClientsSessions
 */
export const deleteAdminDeliveryClientsSessions = (
  params: InputArguments = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `DELETE`,
    url: `/admin/delivery_clients/sessions`,
    params,
  });
};

/**
 * タグの一覧を取得
 */
export const getAdminDeliveryRequestTags = (
  params: InputArguments & {
    query?: {
      fetch_mode?: "paginate" | "all";
      page?: number;
    };
  } = {}
): Promise<TypedResponse<DeliveryRequestTagPage>> => {
  return request({
    method: `GET`,
    url: `/admin/delivery_request_tags`,
    params,
  });
};

/**
 * 企業内でのみ有効なタグの新規作成
 */
export const postAdminDeliveryRequestTags = (
  params: InputArguments & {
    body?: {
      delivery_request_tag: {
        description: string; // タグの説明
        name: string; // タグ名
      };
    };
  } = {}
): Promise<TypedResponse<DeliveryRequestTag>> => {
  return request({
    method: `POST`,
    url: `/admin/delivery_request_tags`,
    params,
  });
};

/**
 * 企業内でのみ有効なタグの更新
 */
export const putAdminDeliveryRequestTagsWithId = (
  params: InputArguments & {
    body?: {
      delivery_request_tag: {
        description: string; // タグの説明
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/admin/delivery_request_tags/${params.path?.id}`,
    params,
  });
};

/**
 * 企業内でのみ有効なタグのアーカイブ
 */
export const putAdminDeliveryRequestTagsWithIdArchive = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/admin/delivery_request_tags/${params.path?.id}/archive`,
    params,
  });
};

/**
 * リクエストのクライアント決済情報
 */
export const getAdminDeliveryRequestsWithIdDeliveryClientCharge = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<DeliveryClientCharge>> => {
  return request({
    method: `GET`,
    url: `/admin/delivery_requests/${params.path?.id}/delivery_client_charge`,
    params,
  });
};

/**
 * リクエストのキャンセル
 */
export const postAdminDeliveryRequestsCanceleds = (
  params: InputArguments & {
    body?: {
      cancel_delivery_request: {
        delivery_request_id: number; // リクエストID
      };
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/admin/delivery_requests/canceleds`,
    params,
  });
};

/**
 * 有効なスキルの一覧を取得
 */
export const getAdminDriverSkills = (
  params: InputArguments & {
    query?: {
      fetch_mode?: "paginate" | "all";
      fetch_type?: "general" | "organization" | "all";
      page?: number;
    };
  } = {}
): Promise<TypedResponse<DriverSkillPage>> => {
  return request({
    method: `GET`,
    url: `/admin/driver_skills`,
    params,
  });
};

/**
 * 企業内でのみ有効な配達パートナースキルの新規作成
 */
export const postAdminDriverSkills = (
  params: InputArguments & {
    body?: {
      description: string; // スキルの説明
      name: string; // スキル名
    };
  } = {}
): Promise<TypedResponse<DriverSkill>> => {
  return request({
    method: `POST`,
    url: `/admin/driver_skills`,
    params,
  });
};

/**
 * 企業内でのみ有効なスキルの更新
 */
export const putAdminDriverSkillsWithId = (
  params: InputArguments & {
    body?: {
      driver_skill: {
        description: string; // スキルの説明
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/admin/driver_skills/${params.path?.id}`,
    params,
  });
};

/**
 * 企業内でのみ有効な配達パートナースキルのアーカイブ
 */
export const putAdminDriverSkillsWithIdArchive = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/admin/driver_skills/${params.path?.id}/archive`,
    params,
  });
};

/**
 * 自社雇用配達パートナーの新規作成
 */
export const postAdminDrivers = (
  params: InputArguments & {
    body?: {
      driver: {
        email?: string; // 指定がない場合は自動生成をする
        family_name: string;
        family_name_furigana: string;
        given_name: string;
        given_name_furigana: string;
        organization_unit_ids: number[];
        phone_number: string;
      };
    };
  } = {}
): Promise<TypedResponse<AdminDriverProfile>> => {
  return request({
    method: `POST`,
    url: `/admin/drivers`,
    params,
  });
};

/**
 * 配達パートナーのプロフィール更新
 */
export const patchAdminDriversWithId = (
  params: InputArguments & {
    body?: {
      profile: {
        email?: string;
        family_name?: string;
        family_name_furigana?: string;
        given_name?: string;
        given_name_furigana?: string;
        organization_unit_ids?: number[];
        phone_number?: string;
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/admin/drivers/${params.path?.id}`,
    params,
  });
};

/**
 * 配達パートナーの申し込んだ案件一覧
 */
export const getAdminDriversWithIdDeliveryEntries = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AdminDeliveryEntryPage>> => {
  return request({
    method: `GET`,
    url: `/admin/drivers/${params.path?.id}/delivery_entries`,
    params,
  });
};

/**
 * 決済失敗している金額
 */
export const getAdminFailedDeliveryClientCharges = (
  params: InputArguments = {}
): Promise<
  TypedResponse<{
    amount: number; // 決済失敗総額
  }>
> => {
  return request({
    method: `GET`,
    url: `/admin/failed_delivery_client_charges`,
    params,
  });
};

/**
 * 住所情報のリバースジオコーディング
 */
export const postAdminGeocodingAddress = (
  params: InputArguments & {
    body?: {
      geocoding_address: {
        address_line_1: string; // 丁目までの住所
        address_line_2: string; // 番地移行の住所
      };
    };
  } = {}
): Promise<TypedResponse<LatLng>> => {
  return request({
    method: `POST`,
    url: `/admin/geocoding_address`,
    params,
  });
};

/**
 * CSVによる自社配達員の一括登録の一覧
 */
export const getAdminInHouseDriverImportBatches = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } = {}
): Promise<
  TypedResponse<{
    in_house_driver_import_batches: InHouseDriverImportBatch[];
    pagination: Pagination;
  }>
> => {
  return request({
    method: `GET`,
    url: `/admin/in_house_driver_import_batches`,
    params,
  });
};

/**
 * CSVによる自社配達員の一括登録
 */
export const postAdminInHouseDriverImportBatches = (
  params: InputArguments = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/admin/in_house_driver_import_batches`,
    params,
  });
};

/**
 * CSVによる自社配達員の一括登録の詳細
 */
export const getAdminInHouseDriverImportBatchesWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<InHouseDriverImportBatch>> => {
  return request({
    method: `GET`,
    url: `/admin/in_house_driver_import_batches/${params.path?.id}`,
    params,
  });
};

/**
 * CSVによる自社配達員の一括登録バッチのエントリー一覧
 */
export const getAdminInHouseDriverImportBatchesWithIdEntries = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<
  TypedResponse<{
    in_house_driver_import_batch_entries: InHouseDriverImportBatchEntry[];
    pagination: Pagination;
  }>
> => {
  return request({
    method: `GET`,
    url: `/admin/in_house_driver_import_batches/${params.path?.id}/entries`,
    params,
  });
};

/**
 * 配送可能なネットワークの一覧を取得
 */
export const postAdminMarketplaceDeliveryNetworkAvailabilities = (
  params: InputArguments & {
    body?: {
      delivery_request: {
        destination: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
        };
        maximum_amount?: number; // 上限金額
        pick_up_point: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
        };
      };
    };
  } = {}
): Promise<
  TypedResponse<{
    delivery_network_availabilities: DeliveryNetworkAvailability[];
  }>
> => {
  return request({
    method: `POST`,
    url: `/admin/marketplace/delivery_network_availabilities`,
    params,
  });
};

/**
 * リクエスト入稿
 */
export const postAdminMarketplaceDeliveryRequests = (
  params: InputArguments & {
    body?: {
      delivery_request: {
        delivery_request_tag_ids?: number[]; // リクエストタグIDの配列
        destinations: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
          message_from_delivery_client?: string; // 集荷先での伝達事項
          name: string; // 集荷場所の名称
          phone_number?: string; // 集荷場所の電話番号
          time_window?: {
            close_at: string; // 配達時間帯終了時刻
            open_at: string; // 配達時間帯開始時刻
          }; // 配達時間帯
        }[];
        maximum_amount: number; // 上限金額
        message_from_delivery_client: string; // クライアントからの伝達事項
        network_types: NetworkType[];
        organization_unit_id?: number; // リクエストの属するユニット
        pick_up_point: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
          message_from_delivery_client?: string; // 集荷先での伝達事項
          min_preparation_time_minutes: number; // 集荷物の準備にかかる時間
          name: string; // 集荷場所の名称
          phone_number?: string; // 集荷場所の電話番号
        };
      };
    };
  } = {}
): Promise<
  TypedResponse<{
    id: number; // リクエストのID
  }>
> => {
  return request({
    method: `POST`,
    url: `/admin/marketplace/delivery_requests`,
    params,
  });
};

/**
 * 年末年始サービス休止期間
 */
export const getAdminNewYearHolidays = (
  params: InputArguments = {}
): Promise<TypedResponse<NewYearHolidays>> => {
  return request({
    method: `GET`,
    url: `/admin/new_year_holidays`,
    params,
  });
};

/**
 * 配達パートナーに待機してもらうエリア
 */
export const getAdminNextDriverStandbyAreas = (
  params: InputArguments & {
    query?: {
      page?: number;
      per_page?: number;
      search?: {
        name_cont?: string; // エリア名の部分一致
        organization_unit_id_eq?: number; // ユニットIDの一致
      };
    };
  } = {}
): Promise<TypedResponse<AdminDriverStandbyAreaPage>> => {
  return request({
    method: `GET`,
    url: `/admin/next/driver_standby_areas`,
    params,
  });
};

/**
 * 配達パートナーに待機してもらうエリアの作成
 */
export const postAdminNextDriverStandbyAreas = (
  params: InputArguments & {
    body?: AdminDriverStandbyAreaCreatePayload;
  } = {}
): Promise<TypedResponse<AdminDriverStandbyArea>> => {
  return request({
    method: `POST`,
    url: `/admin/next/driver_standby_areas`,
    params,
  });
};

/**
 * getAdminNextDriverStandbyAreasWithId
 */
export const getAdminNextDriverStandbyAreasWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AdminDriverStandbyArea>> => {
  return request({
    method: `GET`,
    url: `/admin/next/driver_standby_areas/${params.path?.id}`,
    params,
  });
};

/**
 * putAdminNextDriverStandbyAreasWithId
 */
export const putAdminNextDriverStandbyAreasWithId = (
  params: InputArguments & {
    body?: AdminDriverStandbyAreaUpdatePayload;
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AdminDriverStandbyArea>> => {
  return request({
    method: `PUT`,
    url: `/admin/next/driver_standby_areas/${params.path?.id}`,
    params,
  });
};

/**
 * getAdminNextInhouseDriverShifts
 */
export const getAdminNextInhouseDriverShifts = (
  params: InputArguments & {
    query?: {
      date?: string;
      organization_unit_id?: number;
      page?: number;
      per_page?: number;
    };
  } = {}
): Promise<TypedResponse<AdminInhouseDriverShiftPage>> => {
  return request({
    method: `GET`,
    url: `/admin/next/inhouse_driver_shifts`,
    params,
  });
};

/**
 * postAdminNextInhouseDriverShifts
 */
export const postAdminNextInhouseDriverShifts = (
  params: InputArguments & {
    body?: AdminInhouseDriverShiftCreatePayload;
  } = {}
): Promise<TypedResponse<AdminInhouseDriverShift>> => {
  return request({
    method: `POST`,
    url: `/admin/next/inhouse_driver_shifts`,
    params,
  });
};

/**
 * getAdminNextInhouseDriverShiftsWithId
 */
export const getAdminNextInhouseDriverShiftsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AdminInhouseDriverShift>> => {
  return request({
    method: `GET`,
    url: `/admin/next/inhouse_driver_shifts/${params.path?.id}`,
    params,
  });
};

/**
 * putAdminNextInhouseDriverShiftsWithId
 */
export const putAdminNextInhouseDriverShiftsWithId = (
  params: InputArguments & {
    body?: AdminInhouseDriverShiftUpdatePayload;
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AdminInhouseDriverShift>> => {
  return request({
    method: `PUT`,
    url: `/admin/next/inhouse_driver_shifts/${params.path?.id}`,
    params,
  });
};

/**
 * deleteAdminNextInhouseDriverShiftsWithId
 */
export const deleteAdminNextInhouseDriverShiftsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `DELETE`,
    url: `/admin/next/inhouse_driver_shifts/${params.path?.id}`,
    params,
  });
};

/**
 * postAdminNextInhouseDriverShiftsBulkCreate
 */
export const postAdminNextInhouseDriverShiftsBulkCreate = (
  params: InputArguments & {
    body?: AdminInhouseDriverShiftBulkCreatePayload;
  } = {}
): Promise<TypedResponse<AdminInhouseDriverShift[]>> => {
  return request({
    method: `POST`,
    url: `/admin/next/inhouse_driver_shifts/bulk_create`,
    params,
  });
};

/**
 * 自分の所属している企業アカウントの情報取得
 */
export const getAdminOrganization = (
  params: InputArguments = {}
): Promise<TypedResponse<Organization>> => {
  return request({
    method: `GET`,
    url: `/admin/organization`,
    params,
  });
};

/**
 * 企業アカウントへの招待の作成
 */
export const postAdminOrganizationInvitations = (
  params: InputArguments & {
    body?: {
      organization_invitation: {
        email: string; // メールアドレス
        name: string; // 社員名
        role: "admin" | "member"; // ロール
      };
    };
  } = {}
): Promise<TypedResponse<OrganizationInvitation>> => {
  return request({
    method: `POST`,
    url: `/admin/organization_invitations`,
    params,
  });
};

/**
 * 企業のメンバー一覧
 */
export const getAdminOrganizationMemberships = (
  params: InputArguments & {
    query?: {
      fetch_mode?: "paginate" | "all";
      page?: number;
    };
  } = {}
): Promise<TypedResponse<OrganizationMembershipPage>> => {
  return request({
    method: `GET`,
    url: `/admin/organization_memberships`,
    params,
  });
};

/**
 * ユニットにリクエスト配信対象とする事ができる候補の一覧
 */
export const getAdminOrganizationUnitRecruitmentTargets = (
  params: InputArguments = {}
): Promise<TypedResponse<OrganizationUnitRecruitmentTarget[]>> => {
  return request({
    method: `GET`,
    url: `/admin/organization_unit_recruitment_targets`,
    params,
  });
};

/**
 * ユニット一覧の取得
 */
export const getAdminOrganizationUnits = (
  params: InputArguments & {
    query?: {
      fetch_mode?: "paginate" | "all";
      page?: number;
    };
  } = {}
): Promise<TypedResponse<AdminOrganizationUnitPage>> => {
  return request({
    method: `GET`,
    url: `/admin/organization_units`,
    params,
  });
};

/**
 * 企業のユニットの作成
 */
export const postAdminOrganizationUnits = (
  params: InputArguments & {
    body?: {
      organization_unit: {
        admin_delivery_client_ids: number[]; // ユニットの管理者id一覧
        delivery_request_recruitment_targets?: {
          type: "platform" | "exclusive_standby";
        }[]; // リクエストの配信対象
        member_delivery_client_ids?: number[]; // ユニットのメンバーid一覧
        name: string; // ユニット名
      };
    };
  } = {}
): Promise<TypedResponse<AdminOrganizationUnitDetail>> => {
  return request({
    method: `POST`,
    url: `/admin/organization_units`,
    params,
  });
};

/**
 * 企業のユニット詳細
 */
export const getAdminOrganizationUnitsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AdminOrganizationUnitDetail>> => {
  return request({
    method: `GET`,
    url: `/admin/organization_units/${params.path?.id}`,
    params,
  });
};

/**
 * 企業のユニット更新
 */
export const putAdminOrganizationUnitsWithId = (
  params: InputArguments & {
    body?: {
      organization_unit: {
        admin_delivery_client_ids: number[]; // ユニットの管理者のid
        delivery_request_recruitment_targets?: {
          type: "platform" | "exclusive_standby";
        }[]; // リクエストの配信対象
        driver_standby_shift_default_message_from_delivery_client?: string; // CREW Expressの専属シフトドライバーへのデフォルトの伝達事項
        member_delivery_client_ids: number[]; // ユニットのメンバーid一覧
        name: string; // ユニットの名前
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/admin/organization_units/${params.path?.id}`,
    params,
  });
};

/**
 * getAdminOrganizationUnitsWithIdDrivers
 */
export const getAdminOrganizationUnitsWithIdDrivers = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AdminDriverPage>> => {
  return request({
    method: `GET`,
    url: `/admin/organization_units/${params.path?.id}/drivers`,
    params,
  });
};

/**
 * 企業のユニット一覧の名前
 */
export const getAdminOrganizationUnitsName = (
  params: InputArguments = {}
): Promise<
  TypedResponse<
    {
      name: string;
      organization_unit_id: number;
    }[]
  >
> => {
  return request({
    method: `GET`,
    url: `/admin/organization_units/name`,
    params,
  });
};

/**
 * 企業アカウントの決済手段の取得
 */
export const getAdminOrganizationPaymentMethod = (
  params: InputArguments = {}
): Promise<TypedResponse<OrganizationPaymentMethod>> => {
  return request({
    method: `GET`,
    url: `/admin/organization/payment_method`,
    params,
  });
};

/**
 * 法人カード登録
 */
export const patchAdminOrganizationStripeSource = (
  params: InputArguments & {
    body?: {
      stripe_source: {
        token: string; // Stripe APIで生成したTokenのID
      };
    };
  } = {}
): Promise<TypedResponse<StripeSource>> => {
  return request({
    method: `PATCH`,
    url: `/admin/organization/stripe_source`,
    params,
  });
};

/**
 * クライアントの決済手段の取得
 */
export const getAdminPaymentMethod = (
  params: InputArguments = {}
): Promise<TypedResponse<PaymentMethod>> => {
  return request({
    method: `GET`,
    url: `/admin/payment_method`,
    params,
  });
};

/**
 * 予測される集荷までの時間
 */
export const getAdminPickUpTimePrediction = (
  params: InputArguments & {
    query?: {
      organization_unit_id?: number;
    };
  } = {}
): Promise<TypedResponse<PickUpTimePrediction>> => {
  return request({
    method: `GET`,
    url: `/admin/pick_up_time_prediction`,
    params,
  });
};

/**
 * リクエストの住所履歴一覧
 */
export const getAdminRoutePointHistories = (
  params: InputArguments = {}
): Promise<TypedResponse<RoutePointHistory[]>> => {
  return request({
    method: `GET`,
    url: `/admin/route_point_histories`,
    params,
  });
};

/**
 * クライアントが利用するべきStripeの公開キーの取得
 */
export const getAdminStripePublicKey = (
  params: InputArguments = {}
): Promise<TypedResponse<StripePublicKey>> => {
  return request({
    method: `GET`,
    url: `/admin/stripe_public_key`,
    params,
  });
};

/**
 * クライアントの決済カード情報取得
 */
export const getAdminStripeSource = (
  params: InputArguments = {}
): Promise<TypedResponse<StripeSource>> => {
  return request({
    method: `GET`,
    url: `/admin/stripe_source`,
    params,
  });
};

/**
 * クライアントの決済カード情報更新
 */
export const patchAdminStripeSource = (
  params: InputArguments & {
    body?: {
      stripe_source: {
        token: string; // Stripe APIで生成したTokenのID
      };
    };
  } = {}
): Promise<TypedResponse<StripeSource>> => {
  return request({
    method: `PATCH`,
    url: `/admin/stripe_source`,
    params,
  });
};

/**
 * リクエスト入稿
 */
export const postAdminTmsDeliveryRequests = (
  params: InputArguments & {
    body?: {
      delivery_request: {
        delivery_request_tag_ids?: number[]; // リクエストタグIDの配列
        destinations: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
          message_from_delivery_client?: string; // 集荷先での伝達事項
          name: string; // 集荷場所の名称
          phone_number?: string; // 集荷場所の電話番号
          time_window?: {
            close_at: string; // 配達時間帯終了時刻
            open_at: string; // 配達時間帯開始時刻
          }; // 配達時間帯
        }[];
        driver_skill_ids?: number[]; // 要求する配達パートナースキルIDの配列
        message_from_delivery_client: string; // クライアントからの伝達事項
        optimize_route?: boolean; // ルート最適化を実施するか
        organization_unit_id: number; // リクエストの属するユニット
        pick_up_point: {
          address_line_1: string; // 集荷場所の住所、丁目まで
          address_line_2: string; // 集荷場所の住所、番地以降
          address_line_3?: string; // 集荷場所の住所、建物名・部屋番号
          message_from_delivery_client?: string; // 集荷先での伝達事項
          name: string; // 集荷場所の名称
          phone_number?: string; // 集荷場所の電話番号
        };
      };
    };
  } = {}
): Promise<
  TypedResponse<{
    id: number; // リクエストのID
  }>
> => {
  return request({
    method: `POST`,
    url: `/admin/tms/delivery_requests`,
    params,
  });
};

/**
 * 現在のDeliveryClientの決済失敗一覧
 */
export const getAdminV2AccountFailedDeliveryClientCharges = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } = {}
): Promise<TypedResponse<FailedDeliveryClientChargePageV2>> => {
  return request({
    method: `GET`,
    url: `/admin/v2/account/failed_delivery_client_charges`,
    params,
  });
};

/**
 * リクエスト一覧
 */
export const getAdminV2DeliveryRequests = (
  params: InputArguments & {
    query?: {
      page?: number;
      search?: {
        organization_unit_id?: number; // 紐づいているユニットのID
      };
    };
  } = {}
): Promise<
  TypedResponse<{
    delivery_requests: AdminDeliveryRequestV2[];
    pagination: Pagination;
  }>
> => {
  return request({
    method: `GET`,
    url: `/admin/v2/delivery_requests`,
    params,
  });
};

/**
 * リクエスト詳細
 */
export const getAdminV2DeliveryRequestsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AdminDeliveryRequestDetailV2>> => {
  return request({
    method: `GET`,
    url: `/admin/v2/delivery_requests/${params.path?.id}`,
    params,
  });
};

/**
 * 配送料金などの計算
 */
export const postAdminV2DeliveryRequestsChargeBreakdownEstimate = (
  params: InputArguments & {
    body?: {
      charge_breakdown_estimate: {
        destinations: {
          address_line_1: string;
          address_line_2: string;
        }[];
        pick_up_point: {
          address_line_1: string;
          address_line_2: string;
        };
      };
    };
  } = {}
): Promise<TypedResponse<ChargeBreakdownEstimate>> => {
  return request({
    method: `POST`,
    url: `/admin/v2/delivery_requests/charge_breakdown_estimate`,
    params,
  });
};

/**
 * 企業の配達完了したリクエスト一覧
 */
export const getAdminV2OrganizationDeliveryRequests = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } = {}
): Promise<
  TypedResponse<{
    delivery_requests: OrganizationDeliveryRequestV2[];
    pagination: Pagination;
  }>
> => {
  return request({
    method: `GET`,
    url: `/admin/v2/organization/delivery_requests`,
    params,
  });
};

/**
 * Auth0からユーザーの氏名を取得
 */
export const getApiV1Auth0ProfileFamilyNameGivenName = (
  params: InputArguments & {
    query?: {
      auth0?: {
        id_token: string;
      };
    };
  } = {}
): Promise<TypedResponse<FamilyNameGivenName>> => {
  return request({
    method: `GET`,
    url: `/api/v1/auth0_profile/family_name_given_name`,
    params,
  });
};

/**
 * 銀行口座情報取得
 */
export const getApiV1BankAccount = (
  params: InputArguments = {}
): Promise<TypedResponse<BankAccount>> => {
  return request({
    method: `GET`,
    url: `/api/v1/bank_account`,
    params,
  });
};

/**
 * 銀行口座登録
 */
export const putApiV1BankAccount = (
  params: InputArguments & {
    body?: {
      bank_account: {
        account_family_name: string; // 口座名義人の姓
        account_given_name: string; // 口座名義人の名
        account_number: string; // 7桁の口座番号
        account_type: "normal" | "checking" | "deposit"; // 口座タイプ
        bank_id: number; // BankのID
        branch_code: string; // 3桁の支店番号
      };
    };
  } = {}
): Promise<TypedResponse<BankAccount>> => {
  return request({
    method: `PUT`,
    url: `/api/v1/bank_account`,
    params,
  });
};

/**
 * 銀行口座名義人名の参考値
 */
export const getApiV1BankAccountNameGuide = (
  params: InputArguments = {}
): Promise<TypedResponse<BankAccountName>> => {
  return request({
    method: `GET`,
    url: `/api/v1/bank_account_name_guide`,
    params,
  });
};

/**
 * 銀行一覧
 */
export const getApiV1Banks = (
  params: InputArguments = {}
): Promise<TypedResponse<Bank[]>> => {
  return request({
    method: `GET`,
    url: `/api/v1/banks`,
    params,
  });
};

/**
 * 緊急報告を行う
 */
export const postApiV1DeliveryEntriesWithIdEmergencyContact = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/api/v1/delivery_entries/${params.path?.id}/emergency_contact`,
    params,
  });
};

/**
 * 1to1配信での、配達パートナーへ通知されるもしくは過去に通知されたリクエスト
 */
export const getApiV1DeliveryRequestDriverNominationsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<DeliveryRequestDriverNomination>> => {
  return request({
    method: `GET`,
    url: `/api/v1/delivery_request_driver_nominations/${params.path?.id}`,
    params,
  });
};

/**
 * プッシュ通知されたリクエストを拒否する
 */
export const postApiV1DeliveryRequestDriverNominationsRejecteds = (
  params: InputArguments & {
    body?: {
      delivery_request_driver_nomination: {
        id: number;
      };
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/api/v1/delivery_request_driver_nominations/rejecteds`,
    params,
  });
};

/**
 * リクエスト詳細取得
 */
export const getApiV1DeliveryRequestsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<DeliveryRequestDetail>> => {
  return request({
    method: `GET`,
    url: `/api/v1/delivery_requests/${params.path?.id}`,
    params,
  });
};

/**
 * シフトへのチェックイン
 */
export const putApiV1DriverStandbyShiftCheckIn = (
  params: InputArguments & {
    body?: {
      driver_standby_shift_id: number; // シフトのid
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/api/v1/driver_standby_shift/check_in`,
    params,
  });
};

/**
 * シフトへのチェックアウト
 */
export const putApiV1DriverStandbyShiftCheckOut = (
  params: InputArguments & {
    body?: {
      driver_standby_shift_id: number; // シフトのid
      reason?: string; // 理由
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/api/v1/driver_standby_shift/check_out`,
    params,
  });
};

/**
 * 配達パートナーの現在の運行ステータス取得
 */
export const getApiV1DriverStatus = (
  params: InputArguments = {}
): Promise<TypedResponse<DriverStatus>> => {
  return request({
    method: `GET`,
    url: `/api/v1/driver_status`,
    params,
  });
};

/**
 * 配達パートナーの現在の運行ステータス更新
 */
export const patchApiV1DriverStatus = (
  params: InputArguments & {
    body?: {
      driver_status: {
        status: "inactive" | "waiting_request" | "nominated" | "working";
      };
    };
  } = {}
): Promise<TypedResponse<DriverStatus>> => {
  return request({
    method: `PATCH`,
    url: `/api/v1/driver_status`,
    params,
  });
};

/**
 * ユーザー登録
 */
export const postApiV1Drivers = (
  params: InputArguments & {
    body?: {
      driver: {
        family_name: string;
        family_name_furigana: string;
        given_name: string;
        given_name_furigana: string;
        id_token: string;
        phone_number: string;
        phone_number_verification_code_uuid: string;
      };
    };
  } = {}
): Promise<TypedResponse<DriverProfile>> => {
  return request({
    method: `POST`,
    url: `/api/v1/drivers`,
    params,
  });
};

/**
 * 1to1配信での、配達パートナーへ通知されるリクエスト
 */
export const getApiV1DriversMeDeliveryRequestDriverNomination = (
  params: InputArguments = {}
): Promise<TypedResponse<DeliveryRequestDriverNomination>> => {
  return request({
    method: `GET`,
    url: `/api/v1/drivers/me/delivery_request_driver_nomination`,
    params,
  });
};

/**
 * 配達パートナーの使用している端末のidの登録
 */
export const postApiV1DriversMeDevice = (
  params: InputArguments & {
    body?: {
      driver_device: {
        id: string; // 配達パートナーの使用している端末のid
      };
    };
  } = {}
): Promise<TypedResponse<DriverDevice>> => {
  return request({
    method: `POST`,
    url: `/api/v1/drivers/me/device`,
    params,
  });
};

/**
 * アカウントの削除依頼を作成
 */
export const postApiV1DriversMeDriverAccountDeleteRequest = (
  params: InputArguments & {
    body?: {
      driver_account_delete_request: {
        contact_email: string;
      };
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/api/v1/drivers/me/driver_account_delete_request`,
    params,
  });
};

/**
 * 現在アクティブなシフト
 */
export const getApiV1DriversMeDriverStandbyShiftsActive = (
  params: InputArguments = {}
): Promise<TypedResponse<DriverStandbyShiftDetail>> => {
  return request({
    method: `GET`,
    url: `/api/v1/drivers/me/driver_standby_shifts/active`,
    params,
  });
};

/**
 * 配達パートナーのマッチング方式取得
 */
export const getApiV1DriversMeMatchingMethod = (
  params: InputArguments = {}
): Promise<
  TypedResponse<{
    matching_method: "auto_assign" | "nomination";
  }>
> => {
  return request({
    method: `GET`,
    url: `/api/v1/drivers/me/matching_method`,
    params,
  });
};

/**
 * 給与明細の一覧
 */
export const getApiV1DriversMePayslips = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } = {}
): Promise<TypedResponse<Payslip[]>> => {
  return request({
    method: `GET`,
    url: `/api/v1/drivers/me/payslips`,
    params,
  });
};

/**
 * 給与明細の詳細
 */
export const getApiV1DriversMePayslipsWithIdDeliveryEntries = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<PayslipDeliveryEntry[]>> => {
  return request({
    method: `GET`,
    url: `/api/v1/drivers/me/payslips/${params.path?.id}/delivery_entries`,
    params,
  });
};

/**
 * 配達パートナーのプロフィール取得
 */
export const getApiV1DriversMeProfile = (
  params: InputArguments = {}
): Promise<TypedResponse<DriverProfile>> => {
  return request({
    method: `GET`,
    url: `/api/v1/drivers/me/profile`,
    params,
  });
};

/**
 * 配達パートナーのプロフィール更新
 */
export const putApiV1DriversMeProfile = (
  params: InputArguments & {
    body?: DriverProfileUpdateRequestBody;
  } = {}
): Promise<TypedResponse<DriverProfile>> => {
  return request({
    method: `PUT`,
    url: `/api/v1/drivers/me/profile`,
    params,
  });
};

/**
 * Push通知設定の取得
 */
export const getApiV1DriversMePushConfiguration = (
  params: InputArguments = {}
): Promise<TypedResponse<PushConfiguration>> => {
  return request({
    method: `GET`,
    url: `/api/v1/drivers/me/push_configuration`,
    params,
  });
};

/**
 * Push通知設定の更新
 */
export const putApiV1DriversMePushConfiguration = (
  params: InputArguments & {
    body?: {
      push_configuration: {
        enabled: boolean;
      };
    };
  } = {}
): Promise<TypedResponse<PushConfiguration>> => {
  return request({
    method: `PUT`,
    url: `/api/v1/drivers/me/push_configuration`,
    params,
  });
};

/**
 * Push通知用のトークン設定
 */
export const postApiV1DriversMePushTokens = (
  params: InputArguments & {
    body?: {
      push_token: {
        token: string;
      };
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/api/v1/drivers/me/push_tokens`,
    params,
  });
};

/**
 * 配達パートナーの審査ステータス
 */
export const getApiV1DriversMeVerificationStatus = (
  params: InputArguments = {}
): Promise<TypedResponse<VerificationStatus>> => {
  return request({
    method: `GET`,
    url: `/api/v1/drivers/me/verification_status`,
    params,
  });
};

/**
 * 電話番号確認のコードを発行
 */
export const postApiV1PhoneNumberVerificationCodes = (
  params: InputArguments & {
    body?: {
      phone_number_verification_code: {
        phone_number: string;
      };
    };
  } = {}
): Promise<TypedResponse<PhoneNumberVerificationCode>> => {
  return request({
    method: `POST`,
    url: `/api/v1/phone_number_verification_codes`,
    params,
  });
};

/**
 * 確認コードによる検証
 */
export const putApiV1PhoneNumberVerificationCodesWithUuid = (
  params: InputArguments & {
    body?: {
      phone_number_verification_code: {
        verification_code: string;
      };
    };
  } & {
    path?: {
      uuid?: string;
    };
  } = {}
): Promise<TypedResponse<PhoneNumberVerificationCode>> => {
  return request({
    method: `PUT`,
    url: `/api/v1/phone_number_verification_codes/${params.path?.uuid}`,
    params,
  });
};

/**
 * 1to1配信での、配達パートナーへ通知されるもしくは過去に通知されたリクエスト
 */
export const getApiV2DeliveryRequestDriverNominationsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<DeliveryRequestDriverNominationV2>> => {
  return request({
    method: `GET`,
    url: `/api/v2/delivery_request_driver_nominations/${params.path?.id}`,
    params,
  });
};

/**
 * プッシュ通知されたリクエストを受け入れる
 */
export const postApiV2DeliveryRequestDriverNominationsAccepteds = (
  params: InputArguments & {
    body?: {
      delivery_request_driver_nomination: {
        device_position: {
          device_id: string; // 端末識別子
          latitude: number; // 緯度
          longitude: number; // 経度
        };
        id: number;
      };
    };
  } = {}
): Promise<TypedResponse<DeliveryWork>> => {
  return request({
    method: `POST`,
    url: `/api/v2/delivery_request_driver_nominations/accepteds`,
    params,
  });
};

/**
 * リクエスト一覧取得
 */
export const getApiV2DeliveryRequests = (
  params: InputArguments = {}
): Promise<
  TypedResponse<{
    delivery_requests: DeliveryRequestV2[];
    dynamic_pricing_rate: string; // ダイナミックプライシングの係数
    non_business_hours: NonBusinessHours;
    update_interval_seconds: number; // リストを更新する間隔秒数
    verification_status: VerificationStatus;
  }>
> => {
  return request({
    method: `GET`,
    url: `/api/v2/delivery_requests`,
    params,
  });
};

/**
 * getApiV2DeliveryWorksWithDeliveryWorkIdDeliveryWorkTasksWithId
 */
export const getApiV2DeliveryWorksWithDeliveryWorkIdDeliveryWorkTasksWithId = (
  params: InputArguments & {
    path?: {
      delivery_work_id?: number;
      id?: number;
    };
  } = {}
): Promise<TypedResponse<DeliveryWorkTaskDetail>> => {
  return request({
    method: `GET`,
    url: `/api/v2/delivery_works/${params.path?.delivery_work_id}/delivery_work_tasks/${params.path?.id}`,
    params,
  });
};

/**
 * 配送タスクの完了報告
 */
export const patchApiV2DeliveryWorksWithDeliveryWorkIdDeliveryWorkTasksWithIdReportDropOff =
  (
    params: InputArguments & {
      body?: {
        reason_for_waiting: string; // 待ち時間が発生した理由
        waiting_minutes: number; // 待ち時間
      };
    } & {
      path?: {
        delivery_work_id?: number;
        id?: number;
      };
    } = {}
  ): Promise<TypedResponse<ReportDropOffResult>> => {
    return request({
      method: `PATCH`,
      url: `/api/v2/delivery_works/${params.path?.delivery_work_id}/delivery_work_tasks/${params.path?.id}/report_drop_off`,
      params,
    });
  };

/**
 * 集荷タスクの完了報告
 */
export const patchApiV2DeliveryWorksWithDeliveryWorkIdDeliveryWorkTasksWithIdReportPickUp =
  (
    params: InputArguments & {
      path?: {
        delivery_work_id?: number;
        id?: number;
      };
    } = {}
  ): Promise<TypedResponse<ReportPickUpResult>> => {
    return request({
      method: `PATCH`,
      url: `/api/v2/delivery_works/${params.path?.delivery_work_id}/delivery_work_tasks/${params.path?.id}/report_pick_up`,
      params,
    });
  };

/**
 * 1to1配信での、配達パートナーへ通知されるリクエスト
 */
export const getApiV2DriversMeDeliveryRequestDriverNomination = (
  params: InputArguments = {}
): Promise<TypedResponse<DeliveryRequestDriverNominationV2>> => {
  return request({
    method: `GET`,
    url: `/api/v2/drivers/me/delivery_request_driver_nomination`,
    params,
  });
};

/**
 * 現在アクティブな配達業務タスクを取得する
 */
export const getApiV2DriversMeDeliveryWorksWithDeliveryWorkIdDeliveryWorkTasksActive =
  (
    params: InputArguments & {
      path?: {
        delivery_work_id?: number;
      };
    } = {}
  ): Promise<TypedResponse<DeliveryWorkTaskDetail>> => {
    return request({
      method: `GET`,
      url: `/api/v2/drivers/me/delivery_works/${params.path?.delivery_work_id}/delivery_work_tasks/active`,
      params,
    });
  };

/**
 * 現在アクティブな配達業務を取得する
 */
export const getApiV2DriversMeDeliveryWorksActive = (
  params: InputArguments = {}
): Promise<TypedResponse<DeliveryWork>> => {
  return request({
    method: `GET`,
    url: `/api/v2/drivers/me/delivery_works/active`,
    params,
  });
};

/**
 * 現在チェックインしている自社配達員シフトを取得する
 */
export const getApiV2DriversMeInhouseDriverShiftsActive = (
  params: InputArguments = {}
): Promise<TypedResponse<DeliveryPartnerInhouseDriverShift>> => {
  return request({
    method: `GET`,
    url: `/api/v2/drivers/me/inhouse_driver_shifts/active`,
    params,
  });
};

/**
 * チェックイン可能な自社配達員シフト一覧
 */
export const getApiV2DriversMeInhouseDriverShiftsWaitForCheckIn = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } = {}
): Promise<TypedResponse<DeliveryPartnerInhouseDriverShiftPage>> => {
  return request({
    method: `GET`,
    url: `/api/v2/drivers/me/inhouse_driver_shifts/wait_for_check_in`,
    params,
  });
};

/**
 * 自社配達員シフトへのチェックイン
 */
export const putApiV2InhouseDriverShiftsCheckIn = (
  params: InputArguments & {
    body?: {
      inhouse_driver_shift_id: number; // シフトのid
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/api/v2/inhouse_driver_shifts/check_in`,
    params,
  });
};

/**
 * 自社配達員シフトからのチェックアウト
 */
export const putApiV2InhouseDriverShiftsCheckOut = (
  params: InputArguments & {
    body?: {
      inhouse_driver_shift_id: number; // シフトのid
      reason?: string; // 理由
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/api/v2/inhouse_driver_shifts/check_out`,
    params,
  });
};

/**
 * postAzitAdminAuthorization
 */
export const postAzitAdminAuthorization = (
  params: InputArguments & {
    body?: {
      authuser: string;
      hd: string;
      id_token: string;
      prompt: string;
      state: string;
    };
  } = {}
): Promise<TypedResponse<AzitAdminGoogleOAuthIdTokenAttributes>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/authorization`,
    params,
  });
};

/**
 * putAzitAdminAuthorizationGoogleOauthUrl
 */
export const putAzitAdminAuthorizationGoogleOauthUrl = (
  params: InputArguments & {
    body?: {
      redirect_uri?: string;
    };
  } = {}
): Promise<TypedResponse<AzitAdminGoogleOAuthUrl>> => {
  return request({
    method: `PUT`,
    url: `/azit_admin/authorization/google_oauth_url`,
    params,
  });
};

/**
 * 現在の集荷見込み時間
 */
export const getAzitAdminCurrentCapacity = (
  params: InputArguments = {}
): Promise<TypedResponse<ServiceCapacity>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/current_capacity`,
    params,
  });
};

/**
 * 現在の集荷見込み時間を更新
 */
export const postAzitAdminCurrentCapacity = (
  params: InputArguments & {
    body?: {
      current_capacity: {
        prediction_minutes: number;
      };
    };
  } = {}
): Promise<TypedResponse<ServiceCapacity>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/current_capacity`,
    params,
  });
};

/**
 * クライアント一覧
 */
export const getAzitAdminDeliveryClients = (
  params: InputArguments & {
    query?: {
      page?: number;
      q?: {
        name_cont?: string;
      };
    };
  } = {}
): Promise<TypedResponse<AzitAdminDeliveryClientPage>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/delivery_clients`,
    params,
  });
};

/**
 * クライアント作成
 */
export const postAzitAdminDeliveryClients = (
  params: InputArguments & {
    body?: {
      profile: {
        email: string; // メールアドレス
        name: string; // 名前
        password: string; // パスワード
        phone_number: string; // 電話番号
      };
    };
  } = {}
): Promise<TypedResponse<DeliveryClient>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/delivery_clients`,
    params,
  });
};

/**
 * クライアントのプロフィール取得
 */
export const getAzitAdminDeliveryClientsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDeliveryClient>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/delivery_clients/${params.path?.id}`,
    params,
  });
};

/**
 * クライアントのプロフィール更新
 */
export const patchAzitAdminDeliveryClientsWithId = (
  params: InputArguments & {
    body?: {
      profile: {
        email?: string;
        name?: string;
        password?: string;
        phone_number?: string;
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/azit_admin/delivery_clients/${params.path?.id}`,
    params,
  });
};

/**
 * クライアントのOAuth認証情報の取得
 */
export const getAzitAdminDeliveryClientsWithIdDeliveryClientOAuthClient = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDeliveryClientOAuthClient>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/delivery_clients/${params.path?.id}/delivery_client_o_auth_client`,
    params,
  });
};

/**
 * クライアントのOAuth認証情報の更新
 */
export const patchAzitAdminDeliveryClientsWithIdDeliveryClientOAuthClient = (
  params: InputArguments & {
    body?: {
      delivery_client_o_auth_client: {
        client_id?: string;
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/azit_admin/delivery_clients/${params.path?.id}/delivery_client_o_auth_client`,
    params,
  });
};

/**
 * リクエスト詳細
 */
export const getAzitAdminDeliveryRequestsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDeliveryRequestDetail>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/delivery_requests/${params.path?.id}`,
    params,
  });
};

/**
 * リクエストのキャンセル
 */
export const postAzitAdminDeliveryRequestsWithIdCancel = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/delivery_requests/${params.path?.id}/cancel`,
    params,
  });
};

/**
 * リクエストのドライバー氏名一覧
 */
export const getAzitAdminDeliveryRequestsWithIdDeliveryRequestDriverNominations =
  (
    params: InputArguments & {
      query?: {
        page?: number;
      };
    } & {
      path?: {
        id?: number;
      };
    } = {}
  ): Promise<TypedResponse<AzitAdminDeliveryRequestDriverNominationsPage>> => {
    return request({
      method: `GET`,
      url: `/azit_admin/delivery_requests/${params.path?.id}/delivery_request_driver_nominations`,
      params,
    });
  };

/**
 * 代理手配の配送料
 */
export const postAzitAdminDeliveryRequestsWithIdProxyArrangementDeliveryCharge =
  (
    params: InputArguments & {
      body?: {
        delivery_request: {
          proxy_arrangement_delivery_charge: number;
        };
      };
    } & {
      path?: {
        id?: number;
      };
    } = {}
  ): Promise<TypedResponse<void>> => {
    return request({
      method: `POST`,
      url: `/azit_admin/delivery_requests/${params.path?.id}/proxy_arrangement_delivery_charge`,
      params,
    });
  };

/**
 * 代理手配の終了
 */
export const postAzitAdminDeliveryRequestsWithIdProxyArrangementFinishedEvent =
  (
    params: InputArguments & {
      body?: {
        delivery_request: {
          message_from_azit: string;
        };
      };
    } & {
      path?: {
        id?: number;
      };
    } = {}
  ): Promise<TypedResponse<void>> => {
    return request({
      method: `POST`,
      url: `/azit_admin/delivery_requests/${params.path?.id}/proxy_arrangement_finished_event`,
      params,
    });
  };

/**
 * リクエストの待機時間の更新
 */
export const patchAzitAdminDeliveryRequestsWithIdWaitingMinutes = (
  params: InputArguments & {
    body?: {
      delivery_request: {
        waiting_minutes: number;
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/azit_admin/delivery_requests/${params.path?.id}/waiting_minutes`,
    params,
  });
};

/**
 * アカウント削除依頼の一覧
 */
export const getAzitAdminDriverAccountDeleteRequests = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } = {}
): Promise<TypedResponse<DriverAccountDeleteRequestPage>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/driver_account_delete_requests`,
    params,
  });
};

/**
 * アカウント削除依頼の更新
 */
export const patchAzitAdminDriverAccountDeleteRequestsWithId = (
  params: InputArguments & {
    body?: {
      driver_account_delete_request: {
        status: "done" | "canceled";
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/azit_admin/driver_account_delete_requests/${params.path?.id}`,
    params,
  });
};

/**
 * 全クライアントから利用可能な配達パートナースキルの取得
 */
export const getAzitAdminDriverSkills = (
  params: InputArguments & {
    query?: {
      fetch_mode?: "paginate" | "all";
      page?: number;
    };
  } = {}
): Promise<TypedResponse<DriverSkillPage>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/driver_skills`,
    params,
  });
};

/**
 * 全クライアントから利用可能な配達パートナースキルの新規作成
 */
export const postAzitAdminDriverSkills = (
  params: InputArguments & {
    body?: {
      driver_skill: {
        description: string;
        name: string;
      };
    };
  } = {}
): Promise<TypedResponse<DriverSkill>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/driver_skills`,
    params,
  });
};

/**
 * 全クライアントから利用可能な配達パートナースキルの更新
 */
export const putAzitAdminDriverSkillsWithId = (
  params: InputArguments & {
    body?: {
      driver_skill: {
        description: string; // スキルの説明
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/azit_admin/driver_skills/${params.path?.id}`,
    params,
  });
};

/**
 * 全クライアントから利用可能な配達パートナースキルのアーカイブ
 */
export const putAzitAdminDriverSkillsWithIdArchive = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/azit_admin/driver_skills/${params.path?.id}/archive`,
    params,
  });
};

/**
 * 配達パートナーに待機してもらうエリア
 */
export const getAzitAdminDriverStandbyAreas = (
  params: InputArguments = {}
): Promise<TypedResponse<DriverStandbyArea[]>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/driver_standby_areas`,
    params,
  });
};

/**
 * 配達パートナーに待機してもらうエリアの作成
 */
export const postAzitAdminDriverStandbyAreas = (
  params: InputArguments & {
    body?: {
      driver_standby_area: {
        name: string;
      };
    };
  } = {}
): Promise<TypedResponse<DriverStandbyArea>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/driver_standby_areas`,
    params,
  });
};

/**
 * 配達パートナーのシフト一覧
 */
export const getAzitAdminDriverStandbyShifts = (
  params: InputArguments & {
    query?: {
      date?: string;
    };
  } = {}
): Promise<TypedResponse<DriverStandbyShift[]>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/driver_standby_shifts`,
    params,
  });
};

/**
 * 配達パートナーのシフト作成
 */
export const postAzitAdminDriverStandbyShifts = (
  params: InputArguments & {
    body?: {
      driver_standby_shifts: {
        driver_id: number;
        driver_standby_area_id: number;
        finished_at: string;
        message_from_delivery_client?: string;
        organization_unit_id?: number;
        started_at: string;
      }[];
    };
  } = {}
): Promise<TypedResponse<DriverStandbyShift[]>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/driver_standby_shifts`,
    params,
  });
};

/**
 * 配達パートナーのシフト更新
 */
export const putAzitAdminDriverStandbyShiftsWithId = (
  params: InputArguments & {
    body?: {
      driver_id?: number;
      driver_standby_area_id?: number;
      finished_at?: string;
      message_from_delivery_client?: string;
      organization_unit_id?: number;
      started_at?: string;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<DriverStandbyShift>> => {
  return request({
    method: `PUT`,
    url: `/azit_admin/driver_standby_shifts/${params.path?.id}`,
    params,
  });
};

/**
 * 配達パートナーのシフト削除
 */
export const deleteAzitAdminDriverStandbyShiftsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `DELETE`,
    url: `/azit_admin/driver_standby_shifts/${params.path?.id}`,
    params,
  });
};

/**
 * 配達パートナー一覧
 */
export const getAzitAdminDrivers = (
  params: InputArguments & {
    query?: {
      page?: number;
      q?: {
        full_name_cont?: string;
      };
    };
  } = {}
): Promise<TypedResponse<AzitAdminDriverPage>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/drivers`,
    params,
  });
};

/**
 * 配達パートナーの利用停止期間の更新
 */
export const getAzitAdminDriversWithDriverIdDriverBansWithId = (
  params: InputArguments & {
    path?: {
      driver_id?: number;
      id?: number;
    };
  } = {}
): Promise<TypedResponse<DriverBan>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/drivers/${params.path?.driver_id}/driver_bans/${params.path?.id}`,
    params,
  });
};

/**
 * 配達パートナーの利用停止期間の更新
 */
export const putAzitAdminDriversWithDriverIdDriverBansWithId = (
  params: InputArguments & {
    body?: {
      driver_ban: {
        expired_at: string;
      };
    };
  } & {
    path?: {
      driver_id?: number;
      id?: number;
    };
  } = {}
): Promise<TypedResponse<DriverBan>> => {
  return request({
    method: `PUT`,
    url: `/azit_admin/drivers/${params.path?.driver_id}/driver_bans/${params.path?.id}`,
    params,
  });
};

/**
 * 配達パートナーの利用停止期間の削除
 */
export const deleteAzitAdminDriversWithDriverIdDriverBansWithId = (
  params: InputArguments & {
    path?: {
      driver_id?: number;
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `DELETE`,
    url: `/azit_admin/drivers/${params.path?.driver_id}/driver_bans/${params.path?.id}`,
    params,
  });
};

/**
 * 配達パートナーのプロフィール取得
 */
export const getAzitAdminDriversWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDriverProfileDetail>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/drivers/${params.path?.id}`,
    params,
  });
};

/**
 * 配達パートナーのプロフィール更新
 */
export const patchAzitAdminDriversWithId = (
  params: InputArguments & {
    body?: {
      profile: {
        email?: string;
        family_name?: string;
        family_name_furigana?: string;
        given_name?: string;
        given_name_furigana?: string;
        phone_number?: string;
        skill_ids?: number[];
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/azit_admin/drivers/${params.path?.id}`,
    params,
  });
};

/**
 * アカウントの個人情報の削除
 */
export const patchAzitAdminDriversWithIdDeletePersonalInformation = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/azit_admin/drivers/${params.path?.id}/delete_personal_information`,
    params,
  });
};

/**
 * 配達パートナーの申し込んだ案件一覧
 */
export const getAzitAdminDriversWithIdDeliveryEntries = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDeliveryEntryPage>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/drivers/${params.path?.id}/delivery_entries`,
    params,
  });
};

/**
 * 配達パートナーの利用停止状況取得
 */
export const getAzitAdminDriversWithIdDriverBans = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<DriverBan[]>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/drivers/${params.path?.id}/driver_bans`,
    params,
  });
};

/**
 * 配達パートナーの利用停止の新規作成
 */
export const postAzitAdminDriversWithIdDriverBans = (
  params: InputArguments & {
    body?: {
      driver_ban: {
        expired_at: string;
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<DriverBan>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/drivers/${params.path?.id}/driver_bans`,
    params,
  });
};

/**
 * 配達パートナーの審査状況取得
 */
export const getAzitAdminDriversWithIdVerificationStatus = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<VerificationStatus>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/drivers/${params.path?.id}/verification_status`,
    params,
  });
};

/**
 * 特定の曜日のDP一覧
 */
export const getAzitAdminDynamicPricingRateEntries = (
  params: InputArguments & {
    query?: {
      wday?: number;
    };
  } = {}
): Promise<TypedResponse<DynamicPricingRateEntry[]>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/dynamic_pricing_rate_entries`,
    params,
  });
};

/**
 * 特定の曜日のDP設定をインポート
 */
export const patchAzitAdminDynamicPricingRateEntriesImport = (
  params: InputArguments = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/azit_admin/dynamic_pricing_rate_entries/import`,
    params,
  });
};

/**
 * 専属待機対象のユニット検索
 */
export const getAzitAdminExclusiveStandbyOrganizationUnitsSearch = (
  params: InputArguments & {
    query?: {
      q?: string;
    };
  } = {}
): Promise<TypedResponse<OrganizationUnit[]>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/exclusive_standby_organization_units/search`,
    params,
  });
};

/**
 * 振り込み一覧
 */
export const getAzitAdminMonthlyPayoutToDriverRakutenTotalPayments = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } = {}
): Promise<
  TypedResponse<AzitAdminMonthlyPayoutToDriverRakutenTotalPaymentsPage>
> => {
  return request({
    method: `GET`,
    url: `/azit_admin/monthly_payout_to_driver_rakuten_total_payments`,
    params,
  });
};

/**
 * 配達パートナーに待機してもらうエリア
 */
export const getAzitAdminNextDriverStandbyAreas = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDriverStandbyAreaPage>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/next/driver_standby_areas`,
    params,
  });
};

/**
 * 配達パートナーに待機してもらうエリアの作成
 */
export const postAzitAdminNextDriverStandbyAreas = (
  params: InputArguments & {
    body?: AzitAdminDriverStandbyAreaCreatePayload;
  } = {}
): Promise<TypedResponse<AzitAdminDriverStandbyArea>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/next/driver_standby_areas`,
    params,
  });
};

/**
 * getAzitAdminNextDriverStandbyAreasWithId
 */
export const getAzitAdminNextDriverStandbyAreasWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDriverStandbyArea>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/next/driver_standby_areas/${params.path?.id}`,
    params,
  });
};

/**
 * putAzitAdminNextDriverStandbyAreasWithId
 */
export const putAzitAdminNextDriverStandbyAreasWithId = (
  params: InputArguments & {
    body?: AzitAdminDriverStandbyAreaUpdatePayload;
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDriverStandbyArea>> => {
  return request({
    method: `PUT`,
    url: `/azit_admin/next/driver_standby_areas/${params.path?.id}`,
    params,
  });
};

/**
 * 企業のユニット詳細
 */
export const getAzitAdminOrganizationUnitsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<OrganizationUnit>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/organization_units/${params.path?.id}`,
    params,
  });
};

/**
 * 企業のユニット詳細
 */
export const patchAzitAdminOrganizationUnitsWithId = (
  params: InputArguments & {
    body?: {
      organization_unit: {
        delivery_request_recruitment_targets: {
          type: "platform" | "exclusive_standby";
        }[]; // リクエストの配信対象
        driver_standby_shift_default_message_from_delivery_client?: string; // CREW Expressの専属シフトドライバーへのデフォルトの伝達事項
        name: string; // ユニットの名前
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<OrganizationUnit>> => {
  return request({
    method: `PATCH`,
    url: `/azit_admin/organization_units/${params.path?.id}`,
    params,
  });
};

/**
 * 企業のユニットのリクエスト一覧
 */
export const getAzitAdminOrganizationUnitsWithIdDeliveryRequests = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<
  TypedResponse<{
    delivery_requests: AzitAdminDeliveryClientDeliveryRequestV2[];
    pagination: Pagination;
  }>
> => {
  return request({
    method: `GET`,
    url: `/azit_admin/organization_units/${params.path?.id}/delivery_requests`,
    params,
  });
};

/**
 * 企業アカウント一覧
 */
export const getAzitAdminOrganizations = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminOrganizationsPage>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/organizations`,
    params,
  });
};

/**
 * 企業アカウント作成
 */
export const postAzitAdminOrganizations = (
  params: InputArguments & {
    body?: {
      organization: {
        name: string;
        payment_method: "credit_card" | "invoice";
        proxy_arrangement_enabled: boolean;
        service_plan: "platform" | "exclusive";
      };
    };
  } = {}
): Promise<TypedResponse<Organization>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/organizations`,
    params,
  });
};

/**
 * 企業アカウントの情報の取得
 */
export const getAzitAdminOrganizationsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<Organization>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/organizations/${params.path?.id}`,
    params,
  });
};

/**
 * 企業アカウント更新
 */
export const patchAzitAdminOrganizationsWithId = (
  params: InputArguments & {
    body?: {
      organization: {
        name: string;
        payment_method: "credit_card" | "invoice";
        proxy_arrangement_enabled: boolean;
        service_plan: "platform" | "exclusive";
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<Organization>> => {
  return request({
    method: `PATCH`,
    url: `/azit_admin/organizations/${params.path?.id}`,
    params,
  });
};

/**
 * CSVによる招待の一括入稿
 */
export const patchAzitAdminOrganizationsWithIdOrganizationInvitationsImport = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/azit_admin/organizations/${params.path?.id}/organization_invitations/import`,
    params,
  });
};

/**
 * 企業のメンバー一覧
 */
export const getAzitAdminOrganizationsWithIdOrganizationMemberships = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<OrganizationMembershipPage>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/organizations/${params.path?.id}/organization_memberships`,
    params,
  });
};

/**
 * 企業のユニット一覧
 */
export const getAzitAdminOrganizationsWithIdOrganizationUnits = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<OrganizationUnitPage>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/organizations/${params.path?.id}/organization_units`,
    params,
  });
};

/**
 * 企業のユニットの作成
 */
export const postAzitAdminOrganizationsWithIdOrganizationUnits = (
  params: InputArguments & {
    body?: {
      organization_unit: {
        delivery_request_recruitment_targets: {
          type: "platform" | "exclusive_standby";
        }[]; // リクエストの配信対象
        name: string; // ユニットの名前
      };
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<OrganizationUnit>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/organizations/${params.path?.id}/organization_units`,
    params,
  });
};

/**
 * 企業の決済手段
 */
export const getAzitAdminOrganizationsWithIdPaymentMethod = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminOrganizationPaymentMethod>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/organizations/${params.path?.id}/payment_method`,
    params,
  });
};

/**
 * 待機パートナー検索
 */
export const getAzitAdminStandbyDriversSearch = (
  params: InputArguments & {
    query?: {
      page?: number;
      q?: string;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDriverProfile[]>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/standby_drivers/search`,
    params,
  });
};

/**
 * 一時的な上書きDPの取得
 */
export const getAzitAdminTemporaryDynamicPricingRates = (
  params: InputArguments = {}
): Promise<TypedResponse<TemporaryDynamicPricingRate>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/temporary_dynamic_pricing_rates`,
    params,
  });
};

/**
 * 一時的な上書きDPの設定
 */
export const postAzitAdminTemporaryDynamicPricingRates = (
  params: InputArguments & {
    body?: {
      temporary_dynamic_pricing_rate: {
        ending_at: string; // 上書きDP係数の有効期限
        rate: number; // 一時的に設定するDPの係数
      };
    };
  } = {}
): Promise<TypedResponse<TemporaryDynamicPricingRate>> => {
  return request({
    method: `POST`,
    url: `/azit_admin/temporary_dynamic_pricing_rates`,
    params,
  });
};

/**
 * 一時的な上書きDPの解除
 */
export const deleteAzitAdminTemporaryDynamicPricingRates = (
  params: InputArguments = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `DELETE`,
    url: `/azit_admin/temporary_dynamic_pricing_rates`,
    params,
  });
};

/**
 * リクエスト一覧
 */
export const getAzitAdminV2DeliveryClientsWithIdDeliveryRequests = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<
  TypedResponse<{
    delivery_requests: AzitAdminDeliveryClientDeliveryRequestV2[];
    pagination: Pagination;
  }>
> => {
  return request({
    method: `GET`,
    url: `/azit_admin/v2/delivery_clients/${params.path?.id}/delivery_requests`,
    params,
  });
};

/**
 * リクエスト詳細
 */
export const getAzitAdminV2DeliveryRequestsWithId = (
  params: InputArguments & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDeliveryRequestDetailV2>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/v2/delivery_requests/${params.path?.id}`,
    params,
  });
};

/**
 * 配達パートナーの申し込んだ案件一覧
 */
export const getAzitAdminV2DriversWithIdDeliveryEntries = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<TypedResponse<AzitAdminDeliveryEntryPageV2>> => {
  return request({
    method: `GET`,
    url: `/azit_admin/v2/drivers/${params.path?.id}/delivery_entries`,
    params,
  });
};

/**
 * 企業の配達完了したリクエスト一覧
 */
export const getAzitAdminV2OrganizationsWithIdDeliveryRequests = (
  params: InputArguments & {
    query?: {
      page?: number;
    };
  } & {
    path?: {
      id?: number;
    };
  } = {}
): Promise<
  TypedResponse<{
    delivery_requests: OrganizationDeliveryRequestV2[];
    pagination: Pagination;
  }>
> => {
  return request({
    method: `GET`,
    url: `/azit_admin/v2/organizations/${params.path?.id}/delivery_requests`,
    params,
  });
};

/**
 * postExternalApiDeliveryNetworksMenuWebhook
 */
export const postExternalApiDeliveryNetworksMenuWebhook = (
  params: InputArguments & {
    body?: any;
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/external_api/delivery_networks/menu/webhook`,
    params,
  });
};

/**
 * postExternalApiDeliveryNetworksWoltWebhook
 */
export const postExternalApiDeliveryNetworksWoltWebhook = (
  params: InputArguments & {
    body?: {
      token: string; // JWT encoded payload
    };
  } = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/external_api/delivery_networks/wolt/webhook`,
    params,
  });
};

/**
 * 認証失敗
 */
export const getUnauthenticated = (
  params: InputArguments = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `GET`,
    url: `/unauthenticated`,
    params,
  });
};

/**
 * 認証失敗
 */
export const postUnauthenticated = (
  params: InputArguments = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `POST`,
    url: `/unauthenticated`,
    params,
  });
};

/**
 * 認証失敗
 */
export const putUnauthenticated = (
  params: InputArguments = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PUT`,
    url: `/unauthenticated`,
    params,
  });
};

/**
 * 認証失敗
 */
export const patchUnauthenticated = (
  params: InputArguments = {}
): Promise<TypedResponse<void>> => {
  return request({
    method: `PATCH`,
    url: `/unauthenticated`,
    params,
  });
};
